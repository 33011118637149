// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { processService, usersModel, usersService } from '../../../../../../core/crm';
// Components
import { usersQueryParamsModel } from '../../../../../../core/crm/_models/users/users-query-params.model';
import { processayarevireportsQueryParamsModel } from '../../../../../../core/crm/_models/process/processayarevireports-query-params.model';
import { processayarevireportsModel } from '../../../../../../core/crm/_models/process/processayarevireports.model';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-ayarevireports-list',
    templateUrl: './ayarevireports-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class ayarevireportsListComponent implements OnInit, OnDestroy {
    // Table fields
    // displayedColumns = ['recordid', 'firstname','lastname','tckimlik','vergino', 'email', 'phone', 'address', 'status', 'actions'];
    displayedColumns = ['customertitle','toplam','altin', 'gumus', 'platin','paladyum'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus = '-1';
    ProcessStatus = '2';
    lcp6 = '0';
    
    // Selection
    selection = new SelectionModel<processayarevireportsModel>(true, []);
    reportResult: processayarevireportsModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private processService: processService,
        private cdRef: ChangeDetectorRef
    ) { }


    ngOnInit() {

        this.paginator._intl.itemsPerPageLabel = 'Sayfa başına kayıt sayısı:';
        this.paginator._intl.nextPageLabel = 'Sonraki Sayfa';
        this.paginator._intl.lastPageLabel = 'Son Sayfa';
        this.paginator._intl.previousPageLabel = 'Önceki Sayfa';
        this.paginator._intl.firstPageLabel = 'İlk Sayfa';
        // tslint:disable-next-line: max-line-length
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => { if (length === 0 || pageSize === 0) { return `0 / ${length}`; } length = Math.max(length, 0); const startIndex = page * pageSize; const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} / ${length}`; };

        // If the users changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (
            this.paginator.pageIndex = 0,
            this.loadreportList()
        ));
        this.subscriptions.push(sortSubscription);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadreportList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        // const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        //     // tslint:disable-next-line:max-line-length
        //     debounceTime(150),
        //     distinctUntilChanged(),
        //     tap(() => {
        //         this.paginator.pageIndex = 0;
        //         this.loadreportList();
        //     })
        // )
        //     .subscribe();
        // this.subscriptions.push(searchSubscription);


        this.loadreportList();

    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    loadreportList() {
        this.loading = true;
        this.selection.clear();
        const queryParams = new processayarevireportsQueryParamsModel(
            ' ',//this.searchInput.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
           -1,// Number(this.filterStatus),
        );
        this.processService.reportprocessayarevitop10(queryParams).subscribe(res => {
            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                    this.reportResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                this.paginatorTotal = this.paginator.pageSize;
                this.reportResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });

        this.selection.clear();

    }




    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.Name = searchText;
        if (!searchText) {
            return filter;
        }

        filter.Name = searchText;
        filter.Email = searchText;
        return filter;
    }


   

    editusers(users: usersModel) {
        // let saveMessageTranslateParam = 'SYSTEM.USERS.EDIT.';
        // saveMessageTranslateParam += users.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        // const saveMessage = this.translate.instant(saveMessageTranslateParam);
        // const messageType = users.recordid > 0 ? MessageType.Update : MessageType.Create;
        // const dialogRef = this.dialog.open(ayareviusersEditDialogComponent, { data: { users } });
        // dialogRef.afterClosed().subscribe(res => {
        //     if (res.status === 0) {
        //         this.layoutUtilsService.showActionNotification(saveMessage, messageType);
        //         this.loadusersList();
        //     } else {
        //         this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Kullanıcı Oluşturulamadı.'), '', 'Tamam');
        //     }


        // });
    }
 
   
   

    getItemCssClassByStatus(status: boolean = true): string {
        switch (status) {
            case false:
                return 'danger';
            case true:
                return 'success';

        }
        return '';
    }


    getItemStatusString(status: boolean = true): string {
        switch (status) {
            case false:
                return 'Pasif';
            case true:
                return 'Aktif';

        }
        return '';
    }

  




}
