// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
// Models
import { sitepagesModel } from '../_models/sitepages/sitepages.model';

import { BaseClass } from '../../../BaseClass';
import { map } from 'lodash';
import { ResponseBaseObject } from '../../auth/_models/ResponseBaseObject.model';
import { catchError } from 'rxjs/operators';
import { sitepagesQueryParamsModel } from '../_models/sitepages/sitepages-query-params.model';
import { environment } from '../../../../environments/environment';





@Injectable()
export class sitepagesService {
    API_USERS_URL = '';
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, public baseClass: BaseClass) {
        this.API_USERS_URL = this.baseClass.apiUrl + 'pages';
    }

    // CREATE =>  POST: add a new sitepages to the server
    createsitepages(sitepages: sitepagesModel, sitepagesId: number): Observable<any> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updatesitepages';
        const data: String = JSON.stringify(sitepages);

        return this.http.put(url, data, options);


        // // Note: Add headers if needed (tokens/bearer)
        // const url = this.API_USERS_URL + '/createsitepages';
        // const httpHeaders = this.httpUtils.getHTTPHeaders();
        // return this.http.post<ResponseBaseObject>(url, sitepages, { headers: httpHeaders });
    }

    // READ
    getAllsitepages(): Observable<any> {
        return this.http.get<any>(this.API_USERS_URL+"/getallpages");
    }

    getsitepagesById(sitepagesId: number): Observable<sitepagesModel> {
        return this.http.get<sitepagesModel>(this.API_USERS_URL + `/${sitepagesId}`);
    }
    getsitepagesByUserId(userId: number): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + `/getbyuserid/${userId}`);
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    findsitepages(queryParams: sitepagesQueryParamsModel): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headersParams = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });

        const httpParams = new HttpParams()
            .set('userid', queryParams.userid.toString())
            .set('status', queryParams.Status.toString())
            .set('searchtext', queryParams.searchtext)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
            

        const url = this.API_USERS_URL + '/ForGrid';
        const options = { headers: headersParams, params: httpParams };
        return this.http.get<ResponseBaseObject>(url, options);
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    // UPDATE => PUT: update the sitepages on the server
    updatesitepages(sitepages: sitepagesModel, userId: number): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/update';
        const data: String = JSON.stringify(sitepages);

        return this.http.put(url, data, options);
    }
  
    // UPDATE Status
    updateStatusForsitepages(sitepages: sitepagesModel[], status: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {
            sitepagesForUpdate: sitepages,
            newStatus: status
        };
        const url = this.API_USERS_URL + '/update';
        return this.http.put(url, body, { headers: httpHeaders });
    }

    getAllRoles(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + '/Roles');
    }
}
