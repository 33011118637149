// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, EventEmitter } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../../core/_base/crud';
// Services and Models
import { sitewidgetdetailsModel, sitewidgetdetailsService } from '../../../../../../../core/crm';
import { sitewidgetdetailsQueryParamsModel } from '../../../../../../../core/crm/_models/sitewidgetdetails/sitewidgetdetails-query-params.model';
import { Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-textcontent',
    templateUrl: './textcontent.component.html',
    styleUrls: ['./textcontent.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class textcontentComponent implements OnInit, OnDestroy {
    @Input() PagerowId: number = 0;
    @Input() WidgetId: number = 0;
    @Input() WidgetName: String = '';
    @Input() UserId: number = 0;
    @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();

    // Table fields
    displayedColumns = ['recordid', 'widgetname', 'size', 'widgetorder', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus = '-1';
    ProcessStatus = '2';
    lcp6 = '0';

    // Selection
    selection = new SelectionModel<sitewidgetdetailsModel>(true, []);
    sitewidgetDetailsResult: sitewidgetdetailsModel[] = [];


    form: FormGroup;
    hasFormErrors = false;
    viewLoading = false;

    loading = false;
    paginatorTotal = 0;

    WidgetDetailId: number = 0;

    editorData: string = ''

    // Subscriptions
    private subscriptions: Subscription[] = [];

    public Editor = ClassicEditor;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private fb: FormBuilder,
        // tslint:disable-next-line: no-shadowed-variable
        private sitewidgetdetailsService: sitewidgetdetailsService,
        private cdRef: ChangeDetectorRef
    ) { }


    ngOnInit() {
        this.createForm();
        this.loadsitewidgetdetails();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    loadsitewidgetdetails() {
        this.loading = true;

        this.sitewidgetdetailsService.getsitewidgetdetailsByWidgetId(this.PagerowId).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value);
                this.sitewidgetDetailsResult = data;
                this.loading = false;

                this.WidgetDetailId = this.sitewidgetDetailsResult[0].recordid;


                const control = this.form.controls;

                this.editorData = this.sitewidgetDetailsResult[0].widgetcontent;
                control['text1'].setValue(this.sitewidgetDetailsResult[0].text1);
                control['text2'].setValue(this.sitewidgetDetailsResult[0].text2);


            } else if (res.status === 4) {
                //this.paginatorTotal = this.paginator.pageSize;
                this.sitewidgetDetailsResult = [];
            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }


            this.loading = false;
            this.cdRef.detectChanges();
        });

        this.selection.clear();

    }
    createForm() {

        this.form = this.fb.group({

            text1: ['', Validators.nullValidator],
            text2: ['', Validators.nullValidator],

        });

        this.cdRef.detectChanges();
    }

    preparesitewidgetdetailsModel(): sitewidgetdetailsModel {
        const controls = this.form.controls;
        const sitewidgetdetails = new sitewidgetdetailsModel();
        sitewidgetdetails.recordid = this.WidgetDetailId;
        sitewidgetdetails.widgetcontent = this.editorData;
        sitewidgetdetails.text1 = controls.text1.value;
        sitewidgetdetails.text2 = controls.text2.value;
        return sitewidgetdetails;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.form.controls;
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const data = this.preparesitewidgetdetailsModel();

        this.sendDataWidgetDetails(data);

    }

    sendDataWidgetDetails(WidgetDetails: sitewidgetdetailsModel) {

        this.sitewidgetdetailsService.insertcomponent(WidgetDetails).subscribe(response => {
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
        });
    }


    getWidgetNameString(item: string = ''): string {
        switch (item) {
            case 'textcontent':
                return 'İletişim Bilgileri';
            case 'image':
                return 'Resim';
            case 'liveprice':
                return 'Canlı Piyasalar';
            case 'livepriceScroll':
                return 'Canlı Piyasalar Kayan';
            case 'map':
                return 'Harita';
            case 'textcontent':
                return 'Sayfa Başlığı';
            case 'slider':
                return 'Slider';
            case 'textcontent':
                return 'Yazı Komponenti';
            case 'timeline':
                return 'Zaman Çizelgesi';
            case 'tradingview':
                return 'Trading View Komponenti';
            case 'youtubeembed':
                return 'YouTube Video';
        }
        return '';
    }
    getSizeString(item: string = ''): string {
        switch (item) {
            case 'col-md-1 col-sm-12':
                return '%8';
            case 'col-md-2 col-sm-12':
                return '%16';
            case 'col-md-3 col-sm-12':
                return '%25';
            case 'col-md-4 col-sm-12':
                return '%33';
            case 'col-md-5 col-sm-12':
                return '%41';
            case 'col-md-6 col-sm-12':
                return '%50';
            case 'col-md-7 col-sm-12':
                return '%58';
            case 'col-md-8 col-sm-12':
                return '%66';
            case 'col-md-9 col-sm-12':
                return '%75';
            case 'col-md-10 col-sm-12':
                return '%83';
            case 'col-md-11 col-sm-12':
                return '%91';
            case 'col-12':
                return '%100';
        }
        return '';
    }

    goback() {

        this.pageEvent.emit({
            step: 2,
            Id: this.PagerowId
        });
    }

    detailpage(sitewidget: sitewidgetdetailsModel) {
        this.pageEvent.emit({
            step: 4,
            Id: sitewidget.recordid
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }





}
