// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
// Material

import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';


// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { ayarevicarilerModel, processService, usersModel, usersService } from '../../../../../../core/crm';
import { ayarevicarilerQueryParamsModel } from '../../../../../../core/crm/_models/process/ayarevicariler-query-params.model';
// import { ayarevicarilerEditDialogComponent } from '../ayarevicariler-edit/ayarevicariler-edit.dialog.component';
 import { ayarevicarilerLastValueDialogComponent } from '../ayarevicariler-lastvalue/ayarevicariler-lastvalue.dialog.component';
import { ayarevicarilerService } from '../../../../../../../../src/app/core/crm/_services/ayarevicariler.service';
import { ayarevicarilerEditDialogComponent } from '../ayarevicariler-edit/ayarevicariler-edit.dialog.component';
import { ayarevicarilernewcustomerEditDialogComponent } from '../ayarevicarilernewcustomer-edit/ayarevicarilernewcustomer-edit.dialog.component';





@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-ayarevicarilerlist',
    templateUrl: './ayarevicarilerlist.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class ayarevicarilerlistComponent implements OnInit, OnDestroy {
    @Input() UserId: number;

    // Table fields
    // displayedColumns = ['recordid','companyid','companyuserid','userid','raporno','girismiktar','cikismiktar','giriszamani','cikiszamani','aumilyem','agmilyem','pltmilyem','pldmilyem','ayareviraporno', 'actions'];


    displayedColumns = [ 'customertitle','phone', 'customername', 'customerlastname','lastprocesstime' ,'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

    // filterStatus = '-1';
    filterType = -1;
    selectedusersettings: any = null;

    // Selection
    selection = new SelectionModel<ayarevicarilerModel>(true, []);
    dataResult: ayarevicarilerModel[] = [];
    usersResult: usersModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private processService: processService,
        private userService: usersService,
        private ayarevicarilerService: ayarevicarilerService,
        private cdRef: ChangeDetectorRef
    ) { }


    ngOnInit() {

        this.paginator._intl.itemsPerPageLabel = 'Sayfa başına kayıt sayısı:';
        this.paginator._intl.nextPageLabel = 'Sonraki Sayfa';
        this.paginator._intl.lastPageLabel = 'Son Sayfa';
        this.paginator._intl.previousPageLabel = 'Önceki Sayfa';
        this.paginator._intl.firstPageLabel = 'İlk Sayfa';
        // tslint:disable-next-line: max-line-length
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => { if (length === 0 || pageSize === 0) { return `0 / ${length}`; } length = Math.max(length, 0); const startIndex = page * pageSize; const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} / ${length}`; };

        // If the devices changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (
            this.paginator.pageIndex = 0,
            this.loadList()
        ));
        this.subscriptions.push(sortSubscription);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.loadList();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    // loadUserList() {
    //     this.userService.getAllusersByCompanyid().subscribe(res => {
    //         // this.ayarevicarilerlistService.getayarevicarilerlistByUserId(this.filteruserId).subscribe(res => {

    //         const data = JSON.parse(res.value);
    //         this.usersResult = data;

    //         //this.filteruserId = this.UserId;
    //         this.loadList();
    //         // this.loadStatesList();
    //         this.cdRef.detectChanges();
    //     });
    // }

    loadList() {
        this.loading = true;
        this.selection.clear();
        var companyid = 0;
        var userid = 0;
        const queryParams = new ayarevicarilerQueryParamsModel(
            this.searchInput.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterType
            // 'vergino',
            // 'customercode',
            // 'customertitle'

        );

        this.ayarevicarilerService.findcariler(queryParams).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                 this.dataResult = data;
                // this.selectedusersettings = this.ayarevicarilerlistResult;
                //this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                //   this.ayarevicarilerlistResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                // this.paginatorTotal = this.paginator.pageSize;
                this.dataResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });



        this.selection.clear();

    }


    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.Name = searchText;
        if (!searchText) {
            return filter;
        }

        filter.Name = searchText;
        filter.Email = searchText;
        return filter;
    }



    deleteayarevicarilerlist(item: ayarevicarilerModel) {
        const title: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.TITLE');
        const description: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.WAIT_DESCRIPTION');
        const deleteMessage = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.loadList();
            this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
        });
    }

    addnew() {
        const newayarevicarilerlist = new ayarevicarilerModel();
        newayarevicarilerlist.clear(); // Set all defaults fields
        this.edit(newayarevicarilerlist);
    }
    addnewcustomer() {
        //const newayarevicarilerlist = new ayarevicarilerModel();
        // newayarevicarilerlist.clear(); // Set all defaults fields
        this.newcustomer();
    }
    
    edit(editmodel: ayarevicarilerModel) {
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam += editmodel.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = editmodel.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(ayarevicarilerEditDialogComponent, { data: { editmodel } });
        dialogRef.afterClosed().subscribe(res => {
            if (res !== undefined)
                if (res.status === 0) {
                    this.layoutUtilsService.showActionNotification(saveMessage, messageType);
                    this.loadList();
                } else {
                    this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Cihaz sistemde tanımlıdır.'), '', 'Tamam');
                }
        });
    }
    newcustomer() {
        
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam +=  'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType =  MessageType.Create;
        const dialogRef = this.dialog.open(ayarevicarilernewcustomerEditDialogComponent, { data: {userid:1,companyid:1  } });
        dialogRef.afterClosed().subscribe(res => {
            if (res !== undefined)
                if (res.status === 0) {
                    this.layoutUtilsService.showActionNotification(saveMessage, messageType);
                    this.loadList();
                } else {
                    this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Cihaz sistemde tanımlıdır.'), '', 'Tamam');
                }
        });
    }

    

    pagerows(ayarevicarilerlist: ayarevicarilerModel) {


        // this.pageEvent.emit({
        //     step: 2,
        //     Id: ayarevicarilerlist.recordid,
        //     //userid: this.filteruserId,
        //     usersettings: this.selectedusersettings
        // });
    }

    detailinf(model: ayarevicarilerModel) {
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam += model.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = model.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(ayarevicarilerLastValueDialogComponent, { data: { model } });
        dialogRef.afterClosed().subscribe(res => {

            if (!res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(saveMessage, messageType);
        });
    }




   


    formatnumber(item:string)
    {
            
        return  Number(item);
    }



}
