// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { usersService, usersModel, usersActionToggleLoading } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-users-edit-dialog',
    templateUrl: './users-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class usersEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });

    // Public properties
    users: usersModel;
    usersForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

    selectedusersId = 0;
    usersResult: usersModel[] = [];

    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<usersEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private store: Store<AppState>,
                // tslint:disable-next-line: no-shadowed-variable
                private usersService: usersService,
                private layoutUtilsService: LayoutUtilsService,
                private cdRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.users = this.data.users;
        if (this.users.recordid === null || this.users.recordid === undefined) { this.users.recordid = 0; }
        this.createForm();
        this.isEditrow = true;
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    usersChangeAction() {
        this.cdRef.detectChanges();
    }

    createForm() {
        if (this.users.recordid > 0) {
            this.usersForm = this.fb.group({
                Name: [this.users.firstname, Validators.nullValidator],
                Email: [this.users.email, Validators.nullValidator],
                SocketAddress: [this.users.companyid, Validators.nullValidator],
              

            });
        } else {
            this.usersForm = this.fb.group({
                Name: [this.users.firstname, Validators.nullValidator],
                Email: [this.users.email, Validators.nullValidator],
                SocketAddress: [this.users.firstname, Validators.nullValidator],
             
            });
        }
        this.usersChangeAction();
    }

    getTitle(): string {
        if (this.users.recordid > 0) {
            return `Düzenlenen Kullanıcı :   ${this.users.tckimlik}  `;
        }

        return 'Yeni Kullanıcı';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.usersForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    prepareusers(): usersModel {
        const controls = this.usersForm.controls;
        const users = new usersModel();
        if (this.users.recordid > 0) {
            users.recordid = this.users.recordid;
            users.firstname = controls.Name.value;
            users.firstname = controls.SocketAddress.value;
           // users.Email = controls.Email.value;
            // _users.Status = controls.Status.value;
        } else {
            users.recordid = 0;
            users.password = "###";
            users.recordid = this.users.recordid;
            users.firstname = controls.Name.value;
            users.firstname = controls.SocketAddress.value;
            users.email = controls.Email.value;
        }

        return users;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.usersForm.controls;
        if (this.usersForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const editedusers = this.prepareusers();
        if (editedusers.recordid > 0) {
            this.updateusers(editedusers);
        } else {
            this.createusers(editedusers);
        }
    }

    updateusers(users: usersModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.usersService.updateusers(users, Number(localStorage.getItem('UserId'))).subscribe(response => {


            
              if (response.status === 0) {
                this.store.dispatch(this.hideActionLoadingDistpatcher);
                this.dialogRef.close({ insertedid: response.value, status: response.status });
                // this.layoutUtilsService.alertElement('İşlem başarıyla gerçekleşti', 'İşlem başarılı!', '', 'Tamam');
            } 
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }

            // this.store.dispatch(this.hideActionLoadingDistpatcher);
            // this.dialogRef.close({ insertedid: response.value, status: response.status });
        });

    }

    createusers(users: usersModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.usersService.createusers(users, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
            this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }

 

}
