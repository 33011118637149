export class PageConfig {
    public defaults: any = {
        dashboard: {
            page: {
                'title': 'Ana Sayfa',
                'desc': 'Latest updates and statistic charts'
            },
        },
        header: {
            actions: {
                page: {title: 'Actions', desc: 'Actions example page'}
            }
        },
        profile: {
            page: {title: 'User Profile', desc: ''}
        },
        error: {
            404: {
                page: {title: '404 Not Found', desc: '', subheader: false}
            },
            403: {
                page: {title: '403 Access Forbidden', desc: '', subheader: false}
            }
        },
        crm: {
            users: {
                page: {title: 'Kullanıcılar', desc: ''}
            },
            sitepages: {
                page: {title: 'Sayfalar', desc: ''}
            },
        }
    };

    public get configs(): any {
        return this.defaults;
    }
}
