// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login, User, UserLoaded } from '../../../../core/auth';

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
    EMAIL: 'admin@ayarevi.com',
    PASSWORD: '123456'
};
// const DEMO_PARAMS = {
//     EMAIL: '',
//     PASSWORD: ''
// };

@Component({
    selector: 'kt-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
    // Public params
    loginForm: FormGroup;
    loading = false;
    isLoggedIn$: Observable<boolean>;
    errors: any = [];

    title:string="Müşteri Oturum Aç";



    private unsubscribe: Subject<any>;

    private returnUrl: any;

    constructor(
        private router: Router,
        private auth: AuthService,
        private authNoticeService: AuthNoticeService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute
    ) {
        this.unsubscribe = new Subject();
    }

    ngOnInit(): void {
        this.initLoginForm();

        // redirect back to the returnUrl before login
        this.route.queryParams.subscribe(params => {
            this.returnUrl = params.returnUrl || '/';
        });
    }

    ngOnDestroy(): void {
        this.authNoticeService.setNotice(null);
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    initLoginForm() {
        // demo message to show
        if (!this.authNoticeService.onNoticeChanged$.getValue()) {
            const initialNotice = `Lütfen Giriş Yapınız.`;
            this.authNoticeService.setNotice(initialNotice, 'info');
        }

        this.loginForm = this.fb.group({
            email: [DEMO_PARAMS.EMAIL, Validators.compose([
                Validators.required,
                Validators.email,
                Validators.minLength(3),
                Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
            ])
            ],
            password: [DEMO_PARAMS.PASSWORD, Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ]
        });
    }

    submit() {
        const controls = this.loginForm.controls;
        /** check form */
        if (this.loginForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        const authData = {
            email: controls.email.value,
            password: controls.password.value
        };
if(this.title=="Ayarevi Oturum Aç")
        this.auth
            .loginayarevi(authData.email, authData.password)
            .pipe(
                tap(response => {
                    if (response.status === 0) {

                        var getuser = JSON.parse(response.value);

                        this.store.dispatch(new Login({ authToken: response.token, userId: getuser.recordid, roleId: getuser.roleid }));
                        // window.location.href = this.returnUrl;
                        this.router.navigateByUrl(this.returnUrl); // Main page
                    } else {
                        this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
                    }
                }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
            else
            this.auth
            .login(authData.email, authData.password)
            .pipe(
                tap(response => {
                    if (response.status === 0) {

                        var getuser = JSON.parse(response.value);

                        this.store.dispatch(new Login({ authToken: response.token, userId: getuser.recordid, roleId: getuser.roleid }));
                        // window.location.href = this.returnUrl;
                        this.router.navigateByUrl(this.returnUrl); // Main page
                    } else {
                        this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
                    }
                }),
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
    getttitle( a)
    {
        this.title = a;

    }
}
