// Angular
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { NotificationsService } from '../../../../../core/system/_services/notifications.service';
// import { NotificationModel } from '../../../../../core/system/_models/notifications/notification.model';


@Component({
    selector: 'kt-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit {

    // Show dot on top of the icon
    @Input() dot: string;

    // Show pulse on icon
    @Input() pulse: boolean;

    @Input() pulseLight: boolean;

    // Set icon class name
    @Input() icon = 'flaticon2-bell-alarm-symbol';
    @Input() iconType: '' | 'success';

    // Set true to icon as SVG or false as icon class
    @Input() useSVG: boolean;

    // Set bg image path
    @Input() bgImage: string;

    // Set skin color, default to light
    @Input() skin: 'light' | 'dark' = 'light';

    @Input() type: 'brand' | 'success' = 'success';


    //  notificationResult: NotificationModel[] = [];
// , private notificationsService: NotificationsService
    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        // this.loadNotificationList();
    }

    // loadNotificationList() {
    //     this.notificationsService.getNotificationsByUserId(Number(localStorage.getItem('UserId')))
    //         .subscribe(response => {
    //             this.notificationResult = response.value;
    //         });
    // }

    backGroundStyle(): string {
        if (!this.bgImage) {
            return 'none';
        }

        return 'url(' + this.bgImage + ')';
    }
}
