// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, EventEmitter } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { sitepagerowsModel, sitepagerowsService } from '../../../../../../core/crm';
import { sitepagerowsQueryParamsModel } from '../../../../../../core/crm/_models/sitepagerows/sitepagerows-query-params.model';
import { Output } from '@angular/core';
import { sitepagesrowsEditDialogComponent } from '../sitepagesrows-edit/sitepagesrows-edit.dialog.component';
import { TranslateService } from '@ngx-translate/core';





@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitepages-rows',
    templateUrl: './sitepages-rows.component.html',
    styleUrls: ['./sitepages-rows.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class sitepagesRowsComponent implements OnInit, OnDestroy {
    @Input() PageId: number = 0;
    @Input() userSettings: any = null;
    @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();

    // Table fields
    displayedColumns = ['recordid', 'contentsize', 'bgcolor', 'bgcolor2', 'bgimage', 'pageorder', 'status' , 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus = '-1';
    ProcessStatus = '2';
    lcp6 = '0';

    // Selection
    selection = new SelectionModel<sitepagerowsModel>(true, []);
    sitepagerowResult: sitepagerowsModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitepagerowsService: sitepagerowsService,
        private cdRef: ChangeDetectorRef
    ) { }


    ngOnInit() {
        this.loadsitepagesRows();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    loadsitepagesRows() {
        this.loading = true;

        // this.sitepagesService.findsitepages(queryParams).subscribe(res => {
        this.sitepagerowsService.getsitepagerowsByPageId(this.PageId).subscribe(res => {
            if (res.status === 0) {
                const data = JSON.parse(res.value);
                this.sitepagerowResult = data;
                this.loading = false;

            } else if (res.status === 4) {
                //this.paginatorTotal = this.paginator.pageSize;
                this.sitepagerowResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });

        this.selection.clear();

    }

    editsitepagerow(users: sitepagerowsModel) {
        let saveMessageTranslateParam = 'SYSTEM.USERS.EDIT.';
        saveMessageTranslateParam += users.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = users.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(sitepagesrowsEditDialogComponent, { data: { users, settings: this.userSettings } });
        dialogRef.afterClosed().subscribe(res => {
            if (res !== undefined) {
                if (res.status === 0) {
                    this.layoutUtilsService.showActionNotification(saveMessage, messageType);
                    this.loadsitepagesRows();
                } else {
                    this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Kullanıcı Oluşturulamadı.'), '', 'Tamam');
                }
            }


        });
    }

    deletesitepages(item: sitepagerowsModel) {
        const title: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.TITLE');
        const description: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.WAIT_DESCRIPTION');
        const deleteMessage = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.loadsitepagesRows();
            this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
        });
    }

    addnewitem() {
        const newsitepages = new sitepagerowsModel();
        newsitepages.clear(); // Set all defaults fields
        newsitepages.recordid = 0;
        newsitepages.pageid = this.PageId;
        this.editsitepagerow(newsitepages);
    }

    // editsitepagerow(sitepages: sitepagerowsModel) {


    //         }





    getItemCssClassByStatus(status: boolean = true): string {
        switch (status) {
            case false:
                return 'danger';

            case true:
                return 'success';
        }
        return '';
    }


    getItemStatusString(status: boolean = true): string {
        switch (status) {
            case false:
                return 'Pasif';
            case true:
                return 'Aktif';

        }
        return '';
    }


    getContentSizeString(item: string = ''): string {
        switch (item) {
            case 'container':
                return 'Normal Genişlik';
            case 'container-fluid':
                return 'Tam Genişlik';

        }
        return '';
    }


    goback() {

        this.pageEvent.emit({
            step: 1,
            Id: this.PageId
        });
    }

    detailpage(siterowpages: sitepagerowsModel) {
        this.pageEvent.emit({
            step: 3,
            Id: siterowpages.recordid
        });
    }







}
