
import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class processayarevireportsModel extends BaseModel {
    id: number;
    recordid: number;
    customertitle: string;
    toplam: string;
    altin:string;
    gumus: string;
    platin: string;
    paladyum: string;
    pltmilyem: string;
    pldmilyem: string;
    


   
  clear() {
        this.id = 0;
        this.recordid = 0;
        
        this.customertitle= '';
        this.toplam= '0';
        this.altin= '0';
        this.gumus= '0';
        this.platin= '0';
        this.paladyum= '0';
    }
}
