export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            items: [

            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: 'Ana Sayfa',
                    root: true,
                    icon: 'flaticon2-architecture-and-city',
                    page: '/dashboard',
                    translate: 'MENU.DASHBOARD',
                    bullet: 'dot',
                },
                { section: 'Sistem' },
                {
                    title: 'PERSONEL ',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-user-outline-symbol',
                    permission: 'AE.CompanyUsers',
                    submenu: [
                        {
                            title: 'Ayarevi Personel İşlemleri',
                            page: '/crm/companyusers',
                            permission: 'AE.CompanyUsers'
                        }
                    ]
                },
                {
                    title: 'CARİLER ',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-group',
                    permission: 'AE.CompanyCustomers',
                    submenu: [
                        {
                            title: 'Cari İşlemleri',
                            page: '/crm/customers',
                            permission: 'AE.CompanyCustomers'
                        }
                    ]
                },
                {
                    title: 'İŞLEMLER ',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-list',
                    permission: 'AE.Product',
                    submenu: [
                        {
                            title: 'İşlemler',
                            page: '/crm/process',
                            permission: 'AE.Product'
                        }
                    ]
                },
                {
                    title: 'RAPORLAR ',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-graphic-1',
                    permission: 'AE.Product',
                    submenu: [
                        {
                            title: 'Top 10 Müşteri',
                            page: '/crm/top10',
                            permission: 'AE.Product'
                        },
                        {
                            title: 'Aylık İşlem Raporu',
                            page: '/crm/monthly',
                            permission: 'AE.Product'
                        },
                        {
                            title: 'Toplam İşlem Hacmi',
                            page: '/crm/monthly',
                            permission: 'AE.Product'
                        },
                        {
                            title: 'Personel Performans Raporu',
                            page: '/crm/monthly',
                            permission: 'AE.Product'
                        }
                       
                    ]
                },
                {
                    title: 'İŞLEMLER ',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-list',
                    permission: 'AE.MyProduct',
                    submenu: [
                        {
                            title: 'İşlemler',
                            page: '/crm/myprocess',
                            permission: 'AE.MyProduct'
                        }
                    ]
                },
                {
                    title: 'BENİM SAYFAM ',
                    root: true,
                    bullet: 'dot',
                    icon: 'flaticon2-list',
                    permission: 'AE.MyPage',
                    submenu: [
                        {
                            title: 'Kullanıcı Bilgileri',
                            page: '/crm/mypage',
                            permission: 'AE.MyPage'
                        }
                    ]
                },
                
                

            ]
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
