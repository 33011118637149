import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {
    token: string;
    accessToken: string;
    refreshToken: string;
    Role: string[];
    id: number;
    recordid: number;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    countryid: number;
    cityid: number;
    stateid: number;
    usertype: number;
    companyid: number;
    tckimlik: string;
    vergino: string;
    adress: string;
    phone: string;
    code : string
    
    roleid: number;

    clear(): void {
        this.token = '';
        this.accessToken = 'access-token-' + Math.random();
        this.refreshToken = 'access-token-' + Math.random();
        this.Role = [];
        this.id = 0;
        this.recordid = 0;
        this.password = '';
        this.email = '';
        this.firstname = '';
        this.lastname = '';
        this.countryid = 0;
        this.cityid = 0;
        this.stateid = 0;
        this.companyid=0;
        this.tckimlik = '';
        this.vergino = '';
        this.adress = '';
        this.phone = '';
        this.code = '';
        
        this.roleid = 0;
        
    }
}
