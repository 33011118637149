// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Fake API Angular-in-memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Translate Module
import { TranslateModule } from '@ngx-translate/core';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// UI
import { PartialsModule } from '../../../partials/partials.module';
// Auth
import { ModuleGuard } from '../../../../core/auth';

import { NgSelectModule } from '@ng-select/ng-select';

// Core => Services
import {
    crmReducer,
    CrmEffects,
    CrmService,
    usersService,
    sitepagesService,
    sitepagerowsService,
    sitewidgetsService,
    sitewidgetdetailsService,
    ayarevicarilerService,

    processService
} from '../../../../core/crm';

// Core => Utils
import {
    HttpUtilsService,
    TypesUtilsService,
    InterceptService,
    LayoutUtilsService
} from '../../../../core/_base/crud';
// Shared
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    AlertEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../partials/content/crud';


// Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


import { NgbProgressbarModule, NgbProgressbarConfig, NgbTooltipModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { usersListComponent } from './users/users-list/users-list.component';
import { usersEditDialogComponent } from './users/users-edit/users-edit.dialog.component';
import { usersettingsEditDialogComponent } from './users/usersettings-edit/usersettings-edit.dialog.component';

import { sitepagesListComponent } from './sitepages/sitepages-list/sitepages-list.component';
import { sitepagesEditDialogComponent } from './sitepages/sitepages-edit/sitepages-edit.dialog.component';
import { sitepagesrowsEditDialogComponent } from './sitepages/sitepagesrows-edit/sitepagesrows-edit.dialog.component';
import { sitewidgetsEditDialogComponent } from './sitepages/sitewidgets-edit/sitewidgets-edit.dialog.component';
import { CrmComponent } from './crm.component';

import { QuillModule } from 'ngx-quill'
import { sitepagesRowsComponent } from './sitepages/sitepages-rows/sitepages-rows.component';
import { sitewidgetsComponent } from './sitepages/sitewidgets/sitewidgets.component';
import { sitepagesComponent } from './sitepages/sitepages/sitepages.component';
import { contactdetailComponent } from './sitepages/widgets/contactdetail/contactdetail.component';
import { pagetitleComponent } from './sitepages/widgets/pagetitle/pagetitle.component';
import { textcontentComponent } from './sitepages/widgets/textcontent/textcontent.component';
import { imageComponent } from './sitepages/widgets/image/image.component';
import { youtubeembedComponent } from './sitepages/widgets/youtubeembed/youtubeembed.component';
import { livepriceScrollComponent } from './sitepages/widgets/livepriceScroll/livepriceScroll.component';
import { sliderComponent } from './sitepages/widgets/slider/slider/slider.component';
import { sliderEditDialogComponent } from './sitepages/widgets/slider/slider-edit/slider-edit.dialog.component';
import { livepriceComponent } from './sitepages/widgets/liveprice/liveprice.component';
import { timelineComponent } from './sitepages/widgets/timeline/timeline/timeline.component';
import { timelineEditDialogComponent } from './sitepages/widgets/timeline/timeline-edit/timeline-edit.dialog.component';

import { processayareviMainComponent } from './ayarevi/processayarevimain/processayarevimain.component';
import { processayarevilistComponent } from './ayarevi/processayarevilist/processayarevilist.component';
import { processayareviEditDialogComponent } from './ayarevi/processayarevi-edit/processayarevi-edit.dialog.component';
import { processayareviLastValueDialogComponent } from './ayarevi/processayarevi-lastvalue/processayarevi-lastvalue.dialog.component';
import { processayarevicarilerlistComponent } from './ayarevi/processayarevicarilerlist/processayarevicarilerlist.component';

//Müşteri Sayfalar
import { processmusteriMainComponent } from './musteri/processmusterimain/processmusterimain.component';
import { processmusterilistComponent } from './musteri/processmusterilist/processmusterilist.component';
import { processmusteriLastValueDialogComponent } from './musteri/processmusteri-lastvalue/processmusteri-lastvalue.dialog.component';
import { mypageEditDialogComponent } from './musteri/mypage/mypage-edit.dialog.component';


import { ayareviusersEditDialogComponent } from './ayareviusers/ayareviusers-edit/ayareviusers-edit.dialog.component';
import { ayareviusersListComponent } from './ayareviusers/ayareviusers-list/ayareviusers-list.component';


import { ayarevicarilerMainComponent } from './ayarevicariler/ayarevicarilermain/ayarevicarilermain.component';
import { ayarevicarilerlistComponent } from './ayarevicariler/ayarevicarilerlist/ayarevicarilerlist.component';
import { ayarevicarilerLastValueDialogComponent } from './ayarevicariler/ayarevicariler-lastvalue/ayarevicariler-lastvalue.dialog.component';
import { ayarevicarilerEditDialogComponent } from './ayarevicariler/ayarevicariler-edit/ayarevicariler-edit.dialog.component';
import { ayarevicarilernewcustomerEditDialogComponent } from './ayarevicariler/ayarevicarilernewcustomer-edit/ayarevicarilernewcustomer-edit.dialog.component';

import { ayarevireportsListComponent } from './ayarevireports/ayarevireports-list/ayarevireports-list.component';
import { ayarevireportmonthlyListComponent } from './ayarevireports/ayarevireportmonthly-list/ayarevireportmonthly-list.component';





// tslint:disable-next-line:class-name
const routes: Routes = [
    {
        path: '',
        component: CrmComponent,
        // canActivate: [ModuleGuard],
        // data: { moduleName: 'Crm' },
        children: [
            {
                path: '',
                redirectTo: 'users',
                pathMatch: 'full'
            },
            {
                path: 'users',
                component: usersListComponent
            },
            {
                path: 'process',
                component: processayareviMainComponent
            },
            {
                path: 'myprocess',
                component: processmusteriMainComponent
            },
            {
                path: 'mypage',
                component: mypageEditDialogComponent
            },
            {
                path: 'companyusers',
                component: ayareviusersListComponent
            },
            {
                path: 'customers',
                component: ayarevicarilerMainComponent
            },
            {
                path: 'top10',
                component: ayarevireportsListComponent
            }, {
                path: 'monthly',
                component: ayarevireportmonthlyListComponent
            },
        ]
    }
];

@NgModule({
    imports: [
        MatDialogModule,
        CommonModule,
        HttpClientModule,
        PartialsModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        NgbProgressbarModule,
        NgbPopoverModule,
        NgbTooltipModule,
        StoreModule.forFeature('crm', crmReducer),
        EffectsModule.forFeature([CrmEffects]),
        QuillModule.forRoot(),
        CKEditorModule,
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        CrmService,
        usersService,
        sitepagesService,
        sitepagerowsService,
        sitewidgetsService,
        sitewidgetdetailsService,
        TypesUtilsService,
        LayoutUtilsService,
        ayarevicarilerService,
        processService
    ],
    entryComponents: [
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        AlertEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        usersEditDialogComponent,
        usersettingsEditDialogComponent,
        sitepagesEditDialogComponent,
        sitepagesrowsEditDialogComponent,
        sliderEditDialogComponent,
        timelineEditDialogComponent
    ],
    declarations: [
        CrmComponent,
        usersListComponent,
        sitepagesListComponent,
        usersEditDialogComponent,
        sitepagesEditDialogComponent,
        sitepagesrowsEditDialogComponent,
        sitewidgetsEditDialogComponent,
        usersettingsEditDialogComponent,
        sitewidgetsComponent,
        sitepagesRowsComponent,
        sitepagesComponent,
        contactdetailComponent,
        pagetitleComponent,
        textcontentComponent,
        imageComponent,
        youtubeembedComponent,
        livepriceScrollComponent,
        sliderComponent,
        sliderEditDialogComponent,
        livepriceComponent,
        timelineComponent,
        timelineEditDialogComponent,

        processayareviMainComponent,
        processayarevilistComponent,
        processayareviEditDialogComponent,
        processayareviLastValueDialogComponent,
        processayarevicarilerlistComponent,

        processmusteriMainComponent,
        processmusterilistComponent,
        processmusteriLastValueDialogComponent,
        mypageEditDialogComponent,

        ayareviusersListComponent,
        ayareviusersEditDialogComponent,

        ayarevicarilerMainComponent,
        ayarevicarilerlistComponent,
        ayarevicarilerLastValueDialogComponent,
        ayarevicarilerEditDialogComponent,
        ayarevicarilernewcustomerEditDialogComponent,

        ayarevireportsListComponent,
        ayarevireportmonthlyListComponent,
        
        



    ]
})
export class CrmModule { }
