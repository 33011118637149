// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { processService, processayareviModel, CrmActionToggleLoading, usersService, usersModel, usersActionToggleLoading, ayarevicarilerModel } from '../../../../../../core/crm';
import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { processayarevicarilerlistComponent } from '../processayarevicarilerlist/processayarevicarilerlist.component';

import { ayarevicarilerService } from '../../../../../../../../src/app/core/crm/_services/ayarevicariler.service';
import { ayarevicarilerQueryParamsModel } from '../../../../../../core/crm/_models/process/ayarevicariler-query-params.model';
import { TransitionCheckState } from '@angular/material/checkbox';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-processayarevi-edit-dialog',
    templateUrl: './processayarevi-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class processayareviEditDialogComponent implements OnInit, OnDestroy {

    // hideActionLoadingDistpatcher = new CrmActionToggleLoading({ isLoading: false });
    // showActionLoadingDistpatcher = new CrmActionToggleLoading({ isLoading: true });


    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });
    
    @ViewChild('ayareviraporno')
    public ayareviraporno: ElementRef;

    @ViewChild('tckimlik')
    public tckimlik: ElementRef;
    
   


    // Public properties
    processayarevi: processayareviModel;
    userresult: usersModel;
    pageForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;
    isNew = false;

    searchtckimlik = '';
    MusteriSecildimi = false;
    MusteriAdi = '';
    MusteriTitle = '';
    EditType = 0; // 0 ise ilk kez giriyor . 1 ise giriş i yapmış çıkış bilgilerini girecek // 2 bu işlem tamamlanmış. Sadece bilgi için bakıyor . 

    selectedId = 0;
    processayareviResult: processayareviModel[] = [];

    dropdownResult: ayarevicarilerModel[] = [];
    dropdownselectcari ='';
  
    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<processayareviEditDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private fb: FormBuilder,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private processService: processService,
        private usersService: usersService,
        private layoutUtilsService: LayoutUtilsService,
        private ayarevicarilerService: ayarevicarilerService,
        private cdRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.isNew = false;
        this.processayarevi = this.data.editmodel;
        if (this.processayarevi.recordid === null || this.processayarevi.recordid === undefined) { this.processayarevi.recordid = 0; this.isNew = true; }
        this.setEditType();
        this.isEditrow = true;
        this.createForm();

        // setTimeout(() => {
        // this.tckimlik.nativeElement.focus();
            
        // }, 500);

        this.loadListdropdown();

    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    processayareviChangeAction() {
        this.cdRef.detectChanges();
    }

    createForm() {
        this.MusteriAdi = '';
        this.MusteriTitle = '';
        if (this.processayarevi.recordid > 0) {
            this.pageForm = this.fb.group({
                // tckimlik: [this.processayarevi.tckimlik, Validators.nullValidator],
                customertitle: [this.processayarevi.customertitle, Validators.nullValidator],
                customername: [this.processayarevi.customername, Validators.nullValidator],
                ayareviraporno: [this.processayarevi.ayareviraporno, Validators.nullValidator],
                girismiktar: [this.processayarevi.girismiktar, Validators.nullValidator],
                cesnimiktar: [this.processayarevi.cesnimiktar, Validators.nullValidator],
                cikismiktar: [this.processayarevi.cikismiktar , Validators.nullValidator],
                aumilyem: [this.processayarevi.aumilyem, Validators.nullValidator],
                agmilyem: [this.processayarevi.agmilyem, Validators.nullValidator],
                pltmilyem: [this.processayarevi.pltmilyem, Validators.nullValidator],
                pldmilyem: [this.processayarevi.pldmilyem, Validators.nullValidator],
                aciklama: [this.processayarevi.aciklama, Validators.nullValidator],
                dropdownselect : ['',Validators.nullValidator],
            });
            this.MusteriSecildimi = true;
            this.MusteriAdi = this.processayarevi.customername;
            this.MusteriTitle = this.processayarevi.customertitle;

        } else {
          var cem =  localStorage.getItem('tckimlik');
            this.pageForm = this.fb.group({
                name: [this.processayarevi.raporno, Validators.nullValidator],
                tckimlik: [cem===null?'':cem.toString(), Validators.nullValidator],
                customertitle: ['', Validators.nullValidator],
                ayareviraporno: ['', Validators.nullValidator],
                girismiktar: [0, Validators.nullValidator],
                cesnimiktar: [0, Validators.nullValidator],
                cikismiktar: [0, Validators.nullValidator],
                aumilyem: [0, Validators.nullValidator],
                agmilyem: [0, Validators.nullValidator],
                pltmilyem: [0, Validators.nullValidator],
                pldmilyem: [0, Validators.nullValidator],
                aciklama: ['', Validators.nullValidator],
                dropdownselect : ['',Validators.nullValidator],
            });
        }
        //this.processayareviChangeAction();
    }

    getTitle(): string {
        if (this.processayarevi.recordid > 0) {
            return `Düzenlenen İşlem :   ${this.processayarevi.raporno}`;
        }

        return 'Yeni İşlem';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.pageForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    prepareprocessayarevi(): processayareviModel {
        const controls = this.pageForm.controls;
        const sendvalue = new processayareviModel();
        sendvalue.customertitleisnew = 0;

        if (this.processayarevi.recordid > 0) {
            sendvalue.recordid = this.processayarevi.recordid;
            sendvalue.cikismiktar = controls.cikismiktar.value;
            sendvalue.aumilyem = controls.aumilyem.value>0 ? controls.aumilyem.value : "0";
            sendvalue.agmilyem = controls.agmilyem.value>0 ? controls.agmilyem.value : "0";
            sendvalue.pltmilyem = controls.pltmilyem.value>0 ? controls.pltmilyem.value : "0";
            sendvalue.pldmilyem = controls.pldmilyem.value>0 ? controls.pldmilyem.value : "0";
            sendvalue.digermilyem = (1- ((Number(sendvalue.aumilyem)) + (Number(sendvalue.agmilyem)) + (Number(sendvalue.pltmilyem)) + (Number(sendvalue.pldmilyem) ) )).toString();
            sendvalue.aciklama = controls.aciklama.value;
            sendvalue.cikiszamani = this.dateFormatdmy( new Date());

        } else {

            if (this.userresult.customertitle) {
                sendvalue.customertitleisnew = 0;
            }
            else {
                if (controls.customertitle.value) {
                    sendvalue.customertitleisnew = 1;
                    sendvalue.customertitle = controls.customertitle.value;
                }
                else
                    sendvalue.customertitleisnew = 0;
            }
            sendvalue.recordid = 0;
            sendvalue.userid = this.userresult.recordid;
            sendvalue.ayareviraporno = controls.ayareviraporno.value;
            sendvalue.girismiktar = controls.girismiktar.value;
            sendvalue.cesnimiktar = controls.cesnimiktar.value;
            sendvalue.cikismiktar =  ( Number(Number(sendvalue.girismiktar)- Number(sendvalue.cesnimiktar))).toString() ;
            sendvalue.aumilyem = "0";
            sendvalue.agmilyem = "0";
            sendvalue.pltmilyem = "0";
            sendvalue.pldmilyem = "0";
        }

        return sendvalue;
    }

    onBlurMethod(milyem:number){
        // if(milyem===1 )
        // this.pageForm.controls['aumilyem'].setValue("0."+this.pageForm.controls['aumilyem'].value);
        // else    if(milyem===2 && (Number(this.pageForm.controls['agmilyem'].value))>1)
        // this.pageForm.controls['agmilyem'].setValue("0."+this.pageForm.controls['agmilyem'].value);
        // else    if(milyem===3 && (Number(this.pageForm.controls['pltmilyem'].value))>1)
        // this.pageForm.controls['pltmilyem'].setValue("0."+this.pageForm.controls['pltmilyem'].value);
        // else    if(milyem===4 && (Number(this.pageForm.controls['pldmilyem'].value))>1)
        // this.pageForm.controls['pldmilyem'].setValue("0."+this.pageForm.controls['pldmilyem'].value);
    }
    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.pageForm.controls;
        if (this.pageForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const editedprocessayarevi = this.prepareprocessayarevi();


        if (editedprocessayarevi.recordid > 0) {
          
            if(( (Number(editedprocessayarevi.aumilyem)) + 
            (Number(editedprocessayarevi.agmilyem)) + 
            (Number(editedprocessayarevi.pltmilyem)) +
            (Number(editedprocessayarevi.pldmilyem) ) )>1000   )
            {
                this.layoutUtilsService.alertElement('Hatalı Giriş', 'Girdiğiniz milyem değerleri hatalıdır. Girdiğiniz değerler  toplamı " 1000 " değerini geçmemelidir. ', '', 'Tamam');
                return;
            }



            this.updateprocessayarevi(editedprocessayarevi);
        } else {

            if(!editedprocessayarevi.ayareviraporno)
            {
                this.layoutUtilsService.alertElement('Hatalı Giriş', ' Ayarevi rapor numarası boş geçilemez ', '', 'Tamam');
                return;
            }else  if(!( Number(editedprocessayarevi.girismiktar)>0))
            {
                this.layoutUtilsService.alertElement('Hatalı Giriş', ' Giriş miktar sıfırdan büyük olmalıdır', '', 'Tamam');
                return;
            } else if(!( Number(editedprocessayarevi.cesnimiktar)>0))
            {
                this.layoutUtilsService.alertElement('Hatalı Giriş', ' Çeşni miktar sıfırdan büyük olmalıdır ', '', 'Tamam');
                return;
            }else if(( Number(editedprocessayarevi.cesnimiktar)>= Number(editedprocessayarevi.girismiktar)))
            {
                this.layoutUtilsService.alertElement('Hatalı Giriş', ' Çeşni miktar giriş miktardan küçük olmalıdır ', '', 'Tamam');
                return;
            }


            this.updateprocessayarevi(editedprocessayarevi);
            // this.createprocessayarevi(editedprocessayarevi);
        }
    }

    updateprocessayarevi(processayarevi: processayareviModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);

        this.processService.updateprocess(processayarevi).subscribe(response => {
           this.store.dispatch(this.hideActionLoadingDistpatcher);
           this.layoutUtilsService.showActionNotification("Yeni Kayıt Eklendi", MessageType.Create);
           this.pageForm.controls['ayareviraporno'].setValue("");
           this.pageForm.controls['girismiktar'].setValue(0);
           this.pageForm.controls['cesnimiktar'].setValue(0);
           this.ayareviraporno.nativeElement.focus();


            if(processayarevi.recordid>0)
            {
                this.dialogRef.close({ insertedid: response.value, status: response.status });

            }

        });

    }

    // createprocessayarevi(processayarevi: processayareviModel) {
    //     this.store.dispatch(this.showActionLoadingDistpatcher);
    //     this.processService.createprocess(processayarevi, this.userresult.recordid).subscribe(response => {
    //         this.store.dispatch(this.hideActionLoadingDistpatcher);
    //         let StatusMessage = '';
    //         if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
    //             StatusMessage = response.errors[1].messageDescription;
    //         }
    //         this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
    //     });
    // }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }
    clearinput()
    {


        this.pageForm.controls['tckimlik'].setValue("");
     

      //  this.getcarilist();
    }

    getcustomer() {


          const controls = this.pageForm.controls;
          






      //  if(this.dropdownselectcari===null || this.dropdownselectcari=== undefined || this.dropdownselectcari==='') return;

      //  this.store.dispatch(this.showActionLoadingDistpatcher);
      

        //localStorage.setItem('tckimlik',this.pageForm.controls["tckimlik"].value );

        this.usersService.getuserByCode(controls.dropdownselect.value).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                this.userresult = data[0];
                //const controls = this.pageForm.controls;
                this.pageForm.controls['customertitle'].setValue(this.userresult.customertitle);
                // this.pageForm.controls['customername'].setValue(this.userresult.firstname + ' ' + this.userresult.lastname);
                this.MusteriSecildimi = true;
                this.MusteriAdi = this.userresult.firstname + ' ' + this.userresult.lastname;
                this.MusteriTitle = this.userresult.customertitle;
                this.cdRef.detectChanges();
                this.ayareviraporno.nativeElement.focus();



            } else if (res.status === 4) {
                this.MusteriSecildimi = false;
                this.MusteriAdi = '';
                this.MusteriTitle = '';
                // this.paginatorTotal = this.paginator.pageSize;
                this.userresult = null;
                this.layoutUtilsService.alertElement('İşlem Durumu', 'Aranılan kriterlere göre müşteri sistemde bulunamadı. Lütfen kullanıcı oluşturup tekrar deneyiniz', '', 'Tamam');

            }
            else {
                this.MusteriSecildimi = false;
                this.MusteriAdi = '';
                this.MusteriTitle = '';
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            // this.loading = false;
           // this.store.dispatch(this.hideActionLoadingDistpatcher);
            this.cdRef.detectChanges();
        });

    }
    getcarilist() {
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam +=  'UPDATE_MESSAGE' ;
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType =  MessageType.Update ;
        const dialogRef = this.dialog.open(processayarevicarilerlistComponent, { data: {  } });
        dialogRef.afterClosed().subscribe(res => {

            if (!res.data) {
                
            //    this.store.dispatch(this.hideActionLoadingDistpatcher);
                return;
            }
            this.pageForm.controls['tckimlik'].setValue(res.data.code);
          //  this.layoutUtilsService.showActionNotification(saveMessage, messageType);
            this.getcustomer();
        });
    }

    clearUser() {
        this.dropdownselectcari = '';
    }

    loadListdropdown() {
    
        const queryParams = new ayarevicarilerQueryParamsModel(
            "",
            "",
            "",
            0,
            9999,
           1

        );

        this.ayarevicarilerService.findcariler(queryParams).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                 this.dropdownResult = data;
                 this.dropdownselectcari='';
             
                 this.cdRef.detectChanges();
             
         
            } else if (res.status === 4) {
                // this.paginatorTotal = this.paginator.pageSize;
                this.dropdownResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
    
            this.cdRef.detectChanges();
        });


    }



    setEditType() {

        if (this.processayarevi.cikiszamani) {
            this.EditType = 2;
        } else if (this.processayarevi.giriszamani) {
            this.EditType = 1;
        }
        else {
            this.EditType = 0;
        }

    }

    dateFormatdmy(date: Date): string {
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();

        // const hour = date.getHours();
        // const second = date.getSeconds();
        // const minutes = date.getMinutes();
		if (date) {
			return `${day}.${month}.${year}`;
		}

		return '';
	}

   
}
