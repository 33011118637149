// NGRX
import { Action } from '@ngrx/store';

export enum usersActionTypes {
    usersPageToggleLoading = '[users] users Page Toggle Loading',
    usersActionToggleLoading = '[users] users Action Toggle Loading'
}
export class usersPageToggleLoading implements Action {
    readonly type = usersActionTypes.usersPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class usersActionToggleLoading implements Action {
    readonly type = usersActionTypes.usersActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type usersActions = usersPageToggleLoading | usersActionToggleLoading;
