
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../_base/crud';
// Services
import { CrmService } from '../_services/crm.service';
// State
import { AppState } from '../../reducers';
// Actions
import {
    CrmActionTypes,
    CrmPageRequested,
    CrmPageLoaded,
    ManyCrmDeleted,
    OneCrmDeleted,
    CrmActionToggleLoading,
    CrmPageToggleLoading,
    CrmUpdated,
    CrmStatusUpdated,
    CrmCreated,
    CrmOnServerCreated
} from '../_actions/crm.actions';
import { of } from 'rxjs';
import { CrmQueryParamsModel } from '../_models/crm-query-params.model';

@Injectable()
export class CrmEffects {
    showPageLoadingDistpatcher = new CrmPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new CrmActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new CrmActionToggleLoading({ isLoading: false });

    @Effect()
    loadCrmPage$ = this.actions$.pipe(
        ofType<CrmPageRequested>(CrmActionTypes.CrmPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.crmService.findCrm(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: CrmQueryParamsModel = response[1];
            const pageLoadedDispatch = new CrmPageLoaded({
                crm: result.value,
                totalCount: result.value.length > 0 ? result.value[0].rowCount : 0,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    updateCrm$ = this.actions$
        .pipe(
            ofType<CrmUpdated>(CrmActionTypes.CrmUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.crmService.updateCrm(payload.crm, payload.CrmId);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    updateCrmStatus$ = this.actions$
        .pipe(
            ofType<CrmStatusUpdated>(CrmActionTypes.CrmStatusUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.crmService.updateStatusForCrm(payload.crm, payload.status);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createCrm$ = this.actions$
        .pipe(
            ofType<CrmOnServerCreated>(CrmActionTypes.CrmOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.crmService.createCrm(payload.crm, payload.CrmId).pipe(
                    tap(res => {
                        this.store.dispatch(new CrmCreated({ crm: res.value, CrmId: payload.CrmId }));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private crmService: CrmService, private store: Store<AppState>) { }
}
