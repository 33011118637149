
import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class processayareviModel extends BaseModel {
    id: number;
    recordid: number;
    status: number;
    isdeleted: boolean;
    companyid: number;
    companyuserid: number;
    userid: number;
    raporno:string;
    cesnimiktar:string;
    girismiktar: string;
    cikismiktar: string;
    giriszamani:Date;
    cikiszamani:string;
    aumilyem: string;
    agmilyem: string;
    pltmilyem: string;
    pldmilyem: string;
    digermilyem: string;
    ayareviraporno:string;
    customername:string;// Müşteri Adı Soyadı
    customertitle:string;//Müşterinin Ayar evi için title ı 
    customertitleisnew:number;//Müşteri ayar evi title i yeni mi 
    companyusername:string; // İşlem yapan Personel
    companyname:string; // İşlem yapılan Firma Adı
    pdf : string;
    aciklama : string;


   
  clear() {
        this.id = 0;
        this.recordid = 0;
        this.status = 1;
        this.isdeleted = false;
        this.companyid= 0;
        this.companyuserid= 0;
        this.userid= 0;
        this.raporno='';
        this.cesnimiktar='0';
        this.girismiktar= '0';
        this.cikismiktar= '0';
        // this.giriszamani:Date;
        // this.cikiszamani:Date;
        this.aumilyem= '0';
        this.agmilyem= '0';
        this.pltmilyem= '0';
        this.pldmilyem= '0';
        this.digermilyem= '0';
        this.ayareviraporno='';
        this.customername='';// Müşteri Adı Soyadı
        this.customertitle='';//Müşterinin Ayar evi için title ı 
        this.companyusername=''; // İşlem yapan Personel
        this.customertitleisnew = 0;
        this.companyname=''; // İşlem yapılan Firma Adı
        this.pdf ='';
        this.aciklama ='';
    }
}
