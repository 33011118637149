// Angular
import { Component, Inject, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

// import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { CrmService } from '../../../../../core/crm';



export const MY_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'kt-alert-entity-dialog',
    templateUrl: './alert-entity-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'tr-TR' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        // {
        //     provide: DateAdapter,
        //     useClass: MomentDateAdapter,
        //     deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        // },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class AlertEntityDialogComponent implements OnInit {
    // Public properties
    viewLoading = false;
    form: FormGroup;
    formWifi: FormGroup;
    formFHAA: FormGroup;
    formSetVersion: FormGroup;

    formstatusvalue = 1;
    selectedDeviceId = 0;

    formPayments: FormGroup;

    remainingPayment = 0;
    ufeTufe = 0;
    invoiceprice = 0;
    hasFormErrors = false;
    val32bit: any = null;

    constructor(
        public dialogRef: MatDialogRef<AlertEntityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private crmService: CrmService,
        private formBuilder: FormBuilder,
        private adapter: DateAdapter<any>,
        private layoutUtilsService: LayoutUtilsService,
        private store: Store<AppState>,
        private cdRef: ChangeDetectorRef
    ) { this.adapter.setLocale('tr'); }

    ngOnInit() {
        if (this.data.type === 1) {
            this.form = this.formBuilder.group({
                pdf: [''],
                versionname: ['', Validators.required],
                versiondesc: ['', Validators.nullValidator]
            });
            // Wifi Bilgileri Güncelleniyor
        } else if (this.data.type === 2) {
            this.formWifi = this.formBuilder.group({

                wifiname: [this.data.item.AYWA, Validators.required],
                wifipass: [this.data.item.AYWS, Validators.required]
            });
        } else if (this.data.type === 3) {
            this.formFHAA = this.formBuilder.group({

                fgaa: [this.data.item.FGAA, Validators.required],
                fhaa: [this.data.item.FHAA, Validators.required]
            });
        }
        else if (this.data.type === 4) {


        }
        else if (this.data.type === 5) {


        }
        else if (this.data.type === 6) {


        }
        else if (this.data.type === 7) {
            this.formSetVersion = this.formBuilder.group({
                zonename: [this.data.item.Name, Validators.required],
                fhaa: ['', Validators.required]
            });
        }



    }
    loadDeviceList() {
        // this.crmService.getAllRoles()
        //     .subscribe(response => {
        //         this.deviceResult = response.value;

        //         this.cdRef.detectChanges();
        //     });
    }
    getUserId() {
        return Number(localStorage.getItem('UserId'));
    }


    onNoClick(): void {
        this.dialogRef.close({ status: 99 });
    }

    onYesClick(): void {
      
        this.hasFormErrors = false;

        if (this.data.type === 1) {

            // if (this.form.get('pdf').value !== '' && this.form.get('versionname').value !== '') {
            //     this.viewLoading = true;

            //     const formData = new FormData();
            //     formData.append('file', this.form.get('pdf').value);
            //     formData.append('name', this.form.get('versionname').value);
            //     formData.append('description', this.form.get('versiondesc').value);
            //     this.versionsService.createversions2(formData)
            //         .subscribe(response => {
            //             let StatusMessage = '';
            //             if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
            //                 StatusMessage = response.errors[1].messageDescription;
            //             }
            //             this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });


            //         });
            // } else {
            //     this.hasFormErrors = true;
            // }

        } else if (this.data.type === 2) {
            if (this.formWifi.get('wifiname').value !== '' && this.formWifi.get('wifipass').value !== '') {
                this.viewLoading = true;
                // this.data.item.AYWA = "this.formWifi.get('wifiname').value" ;
                // this.data.item.AYWS = "this.formWifi.get('wifipass').value ";
                // this.devicesService.updatedeviceconfigurationwifi(this.data.item.dcRecordId, this.formWifi.get('wifiname').value, this.formWifi.get('wifipass').value)
                //     .subscribe(response => {
                //         const getdata = response.value;
                //         this.dialogRef.close(response);
                //     });

            } else {
                this.hasFormErrors = true;
            }

        } else if (this.data.type === 3) {
            if (this.formFHAA.get('fhaa').value !== '') {
                this.viewLoading = true;

                // this.devicesService.updateversion(this.data.item.AAAA, this.formFHAA.get('fhaa').value)
                //     .subscribe(response => {
                //         const getdata = response.value;
                //         this.dialogRef.close(response);
                //     });

            } else {
                this.hasFormErrors = true;
            }

        }
        else if (this.data.type === 4 || this.data.type === 5 || this.data.type === 6) {
            this.dialogRef.close(null);
        }
        else {
            this.dialogRef.close(null);
        }


    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('pdf').setValue(file);
        }
    }

    getStatusText(status: number, terminationDate: Date, transferDate: Date): string {
        let returnText = '';

        if (status === 1) {
            returnText = 'Aktif';
        } else if (status === 0 && terminationDate !== null && terminationDate.toString() !== '1753-01-01T00:00:00') {
            returnText = 'Fesih Edildi';
        } else if (status === 0 && transferDate !== null && transferDate.toString() !== '1753-01-01T00:00:00') {
            returnText = 'Devir Edildi';
        }
        return returnText;
    }

    getStatusInt(status: number, terminationDate: Date, transferDate: Date): number {
        let returnText = 0;

        if (status === 1) {
            returnText = 1;
        } else if (status === 0 && terminationDate !== null && terminationDate.toString() !== '1753-01-01T00:00:00') {
            returnText = 2;
        } else if (status === 0 && transferDate !== null && transferDate.toString() !== '1753-01-01T00:00:00') {
            returnText = 3;
        }
        return returnText;
    }





}
