import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class sitewidgetdetailsModel extends BaseModel {
    id: number;
    recordid: number;
    status: number;
    isdeleted: boolean;
    widgetid: number;
    widgetcontent: string;
    text: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    text5: string;
    text6: string;
    text7: string;
    text8: string;
    text9: string;
    text10: string;
    lang: string;
    wdorder: number;
   
    clear() {
        this.id = 0;
        this.recordid = 0;
        this.status = 1;
        this.isdeleted = false;
        this.widgetid = 0;
        this.widgetcontent = '';
        this.text = '';
        this.text1 = '';
        this.text2 = '';
        this.text3 = '';
        this.text4 = '';
        this.text5 = '';
        this.text6 = '';
        this.text7 = '';
        this.text8 = '';
        this.text9 = '';
        this.text10 = '';
        this.lang = 'tr';
        this.wdorder = 99;
      }
}
