// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { sitepagesService, sitepagesModel, sitepagesActionToggleLoading } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitepages-edit-dialog',
    templateUrl: './sitepages-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class sitepagesEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new sitepagesActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new sitepagesActionToggleLoading({ isLoading: true });

    // Public properties
    sitepages: sitepagesModel;
    sitepagesForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

    selectedsitepagesId = 0;
    sitepagesResult: sitepagesModel[] = [];

    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<sitepagesEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private store: Store<AppState>,
                // tslint:disable-next-line: no-shadowed-variable
                private sitepagesService: sitepagesService,
                private layoutUtilsService: LayoutUtilsService,
                private cdRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.sitepages = this.data.sitepages;
        if (this.sitepages.recordid === null || this.sitepages.recordid === undefined) { this.sitepages.recordid = 0; }
        this.createForm();
        this.isEditrow = true;
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    sitepagesChangeAction() {
        this.cdRef.detectChanges();
    }

    createForm() {
        if (this.sitepages.recordid > 0) {
            this.sitepagesForm = this.fb.group({
                name: [this.sitepages.name, Validators.nullValidator],

            });
        } else {
            this.sitepagesForm = this.fb.group({
                name: [this.sitepages.name, Validators.nullValidator],
            });
        }
        this.sitepagesChangeAction();
    }

    getTitle(): string {
        if (this.sitepages.recordid > 0) {
            return `Düzenlenen Bölge :   ${this.sitepages.name}`;
        }

        return 'Yeni Bölge';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.sitepagesForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    preparesitepages(): sitepagesModel {
        const controls = this.sitepagesForm.controls;
        const sitepages = new sitepagesModel();
        if (this.sitepages.recordid > 0) {
            sitepages.recordid = this.sitepages.recordid;
            sitepages.name = controls.name.value;
            //sitepages.Status = controls.Status.value;
        } else {
            sitepages.recordid = 0;
            sitepages.name = controls.name.value;
        }

        return sitepages;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.sitepagesForm.controls;
        if (this.sitepagesForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const editedsitepages = this.preparesitepages();
        if (editedsitepages.recordid > 0) {
            this.updatesitepages(editedsitepages);
        } else {
            this.createsitepages(editedsitepages);
        }
    }

    updatesitepages(sitepages: sitepagesModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitepagesService.updatesitepages(sitepages, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            this.dialogRef.close({ insertedid: response.value, status: response.status });
        });

    }

  

    createsitepages(sitepages: sitepagesModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitepagesService.createsitepages(sitepages, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
            this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }
}
