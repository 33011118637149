// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { usersService, usersModel, usersActionToggleLoading } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
// import { NgxMaskModule, IConfig } from 'ngx-mask'

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-users-edit-dialog',
    templateUrl: './ayarevicarilernewcustomer-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class ayarevicarilernewcustomerEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });

    // Public properties
    users: usersModel ;
    usersForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

    selectedusersId = 0;
    usersResult: usersModel[] = [];
 

    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<ayarevicarilernewcustomerEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private usersService: usersService,
        private layoutUtilsService: LayoutUtilsService,
        private cdRef: ChangeDetectorRef) {
    }

  

    ngOnInit() {
        //this.users = this.data.users;
    this.users = new usersModel;
         this.users.recordid = 0; 
        this.createForm();
        this.isEditrow = true;
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    usersChangeAction() {
        this.cdRef.detectChanges();
    }

    createForm() {
        if (this.users.recordid > 0) {
            this.usersForm = this.fb.group({
                firstname: [this.users.firstname, Validators.nullValidator],
                lastname: [this.users.lastname, Validators.nullValidator],
                phone: [this.users.phone, Validators.nullValidator],
                tckimlik: [this.users.tckimlik, Validators.nullValidator],  
                vergino: [this.users.vergino, Validators.nullValidator],
                adress:[this.users.address, Validators.nullValidator],
                // password: ['', Validators.nullValidator],
                // password2: ['', Validators.nullValidator],


            });
        } else {
            this.usersForm = this.fb.group({
                firstname: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(30) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
                lastname: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(30) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
                email: ['', Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.minLength(3),
                    Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])
                ],
                phone: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(10) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
                tckimlik: ['', Validators.compose([
                   // Validators.required,
                    Validators.max(99999999999),
                    Validators.min(10000000000) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
                vergino: ['', Validators.compose([
                 //   Validators.required,
                    Validators.max(9999999999),
                    Validators.min(1000000000) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
                // password: ['', Validators.compose([
                //     Validators.required,
                //     Validators.minLength(6),
                //     Validators.maxLength(20) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                // ])],
                // password2: ['', Validators.compose([
                //     Validators.required,
                //     Validators.minLength(6),
                //     Validators.maxLength(20) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                // ])],
                customertitle: ['', Validators.compose([
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(30) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
                adress: ['', Validators.compose([
                  //  Validators.required,
                    Validators.minLength(10),
                    Validators.maxLength(4000) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
                ])],
            });
        }
        this.usersChangeAction();
    }

    getTitle(): string {
        if (this.users.recordid > 0) {
            return `Düzenlenen Kullanıcı :   ${this.users.email}  `;
        }

        return 'Yeni Kullanıcı';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.usersForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    prepareusers(): usersModel {
        const controls = this.usersForm.controls;
        const users = new usersModel();
        if (this.users.recordid > 0) {
            users.recordid = this.users.recordid;
            users.firstname = controls.firstname.value;
            users.lastname = controls.lastname.value;
            users.phone = controls.phone.value;
            users.tckimlik = controls.tckimlik.value;
            users.vergino = controls.vergino.value;
            users.email = this.users.email;
            users.address = controls.address.value;
            users.password="###"
       

            // if (controls.password.value) {
            //     if (controls.password.value !== controls.password2.value) {
            //         this.layoutUtilsService.alertElement('Şifre Değişikliği', 'Şifreler farklı ! Şifre değiştirmek istemiyorsanız lütfen şifre alalnlarını boş bırakınız', '', 'Tamam');
            //         return;
            //     }
            //     else
            //         users.password = controls.password.value;
            // }

        } else {
            users.recordid = 0;
            users.password = "###";
            users.recordid = this.users.recordid;
            users.firstname = controls.firstname.value;
            users.lastname = controls.lastname.value;
            users.email = controls.email.value;
            users.phone = controls.phone.value;
            users.tckimlik = controls.tckimlik.value;
            users.vergino = controls.vergino.value;
            users.customertitle = controls.customertitle.value;

            // if (controls.password.value !== controls.password2.value) {
            //     this.layoutUtilsService.alertElement('Şifre Değişikliği', 'Şifreler farklı ! Aynı şifreyi girmeniz gerekmektedir. ', '', 'Tamam');
            //     return;
            // }
            // else
            //     users.password = controls.password.value;
        }

        return users;
    }

    onSubmit() {


        
        this.hasFormErrors = false;
        
        const controls = this.usersForm.controls;
        if(!controls.tckimlik.value && !controls.vergino.value ){
            this.hasFormErrors = true;
            this.layoutUtilsService.alertElement('Uyarı', 'Vergi Numarası veya TC Kimlik alanlarından biri dolu olmalıdır', '', 'Tamam');
            return;
}

if(!controls.firstname.value || !controls.lastname.value|| !controls.email.value|| !controls.phone.value || !controls.customertitle.value){
    this.hasFormErrors = true;
    this.layoutUtilsService.alertElement('Uyarı', 'Tüm alanları doldurunuz ', '', 'Tamam');
    return;
}
        
        
        // if (this.usersForm.invalid) {
        //     Object.keys(controls).forEach(controlName =>
        //         controls[controlName].markAsTouched()
        //     );

        //     this.hasFormErrors = true;
        //     return;
        // }



        const editedusers = this.prepareusers();
        if (editedusers.recordid > 0) {
            this.updateusers(editedusers);
        } else {
            this.updateusers(editedusers);
        }
    }

    updateusers(users: usersModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.usersService.updateayarevinewcustomer(users, Number(localStorage.getItem('UserId'))).subscribe(response => {

            if (response.status === 0) {
                this.store.dispatch(this.hideActionLoadingDistpatcher);
                this.dialogRef.close({ insertedid: response.value, status: response.status });
                // this.layoutUtilsService.alertElement('İşlem başarıyla gerçekleşti', 'İşlem başarılı!', '', 'Tamam');
            }else if (response.status === 11) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu email adresi sistemde kayıtlıdır.', '', 'Tamam');
            }else if (response.status === 12) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu telefon bilgisi sistemde kayıtlıdır.', '', 'Tamam');
            }else if (response.status === 13) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu TC Kimlik numarası sistemde kayıtlıdır.', '', 'Tamam');
            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }


        });

    }

    createusers(users: usersModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.usersService.createusers(users, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
            this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }



}
