// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State
import { CrmState } from '../_reducers/crm.reducers';
import { CrmModel } from '../_models/crm.model';

export const selectCrmState = createFeatureSelector<CrmState>('crm');

export const selectCrmById = (crmId: number) => createSelector(
    selectCrmState,
    crmState => crmState.entities[crmId]
);

export const selectCrmPageLoading = createSelector(
    selectCrmState,
    crmState => crmState.listLoading
);

export const selectCrmActionLoading = createSelector(
    selectCrmState,
    crmState => crmState.actionsloading
);

export const selectLastCreatedCrmId = createSelector(
    selectCrmState,
    crmState => crmState.lastCreatedCrmId
);

export const selectCrmShowInitWaitingMessage = createSelector(
    selectCrmState,
    crmState => crmState.showInitWaitingMessage
);

export const selectCrmInStore = createSelector(
    selectCrmState,
    crmState => {
        const items: CrmModel[] = [];
        each(crmState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CrmModel[] = httpExtension.sortArray(items, crmState.lastQuery.sortField, crmState.lastQuery.sortOrder);
        return new QueryResultsModel(result, result, crmState.totalCount, '');
    }
);
