// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { sitepagesModel, sitepagesService } from '../../../../../../core/crm';
// Components
import { sitepagesEditDialogComponent } from '../sitepages-edit/sitepages-edit.dialog.component';
import { sitepagesQueryParamsModel } from '../../../../../../core/crm/_models/sitepages/sitepages-query-params.model';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitepages-list',
    templateUrl: './sitepages-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class sitepagesListComponent implements OnInit, OnDestroy {
    // Table fields
    displayedColumns = ['recordid', 'name', 'status', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus = '-1';
    ProcessStatus = '2';
    lcp6 = '0';
    selectedStep = 1;
    selectedUserId = 0;
    pageId = 0;
    pageRowsId = 0;
    pageWidgetId = 0;
    pageWidgetDetailId = 0;
    widgetName = '';
    selectedusersettings: any = null;

    // Selection
    selection = new SelectionModel<sitepagesModel>(true, []);
    sitepagesResult: sitepagesModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitepagesService: sitepagesService,
        private cdRef: ChangeDetectorRef
    ) { }

    receivePageEvent(event: any) {
        // console.log(event.step)
        // const index = this.subjects
        //   .map((subject) => {
        //     return subject.code;
        //   })
        this.selectedStep = 2;
        this.pageId = event.Id;
        this.selectedUserId = event.userid;
        this.selectedusersettings = event.usersettings;

    }



    receivePageRowsEvent(event: any) {
        // console.log(event.step)
        if (event.step == 1) {
            this.selectedStep = 1;

        }
        else {
            this.selectedStep = 3;
            this.pageRowsId = event.Id;
        }
    }
    receiveWidgetEvent(event: any) {
        // console.log(event.step)
        if (event.step == 2) {
            this.selectedStep = 2;
        }
        else {
            this.selectedStep = 4;
            this.pageWidgetId = event.Id;
            this.widgetName = event.WidgetName;
        }
    }
    receiveWidgetDetailEvent(event: any) {
        // console.log(event.step)
        this.selectedStep = 3;
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    loadsitepagesList() {
        this.loading = true;
        this.selection.clear();
        const queryParams = new sitepagesQueryParamsModel(
            this.searchInput.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            Number(this.filterStatus),
        );

        this.sitepagesService.findsitepages(queryParams).subscribe(res => {
            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                    this.sitepagesResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                this.paginatorTotal = this.paginator.pageSize;
                this.sitepagesResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });

        this.selection.clear();

    }




    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.Name = searchText;
        if (!searchText) {
            return filter;
        }

        filter.Name = searchText;
        filter.Email = searchText;
        return filter;
    }


    deletesitepages(item: sitepagesModel) {
        const title: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.TITLE');
        const description: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.WAIT_DESCRIPTION');
        const deleteMessage = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.loadsitepagesList();
            this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
        });
    }

    addsitepages() {
        const newsitepages = new sitepagesModel();
        newsitepages.clear(); // Set all defaults fields
        this.editsitepages(newsitepages);
    }

    editsitepages(sitepages: sitepagesModel) {
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam += sitepages.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = sitepages.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(sitepagesEditDialogComponent, { data: { sitepages } });
        dialogRef.afterClosed().subscribe(res => {
            if (res.status === 0) {
                this.layoutUtilsService.showActionNotification(saveMessage, messageType);
                this.loadsitepagesList();
            } else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Cihaz sistemde tanımlıdır.'), '', 'Tamam');
            }


        });
    }





    getItemCssClassByStatus(status: boolean = true): string {
        switch (status) {
            case false:
                return 'danger';

            case true:
                return 'success';
        }
        return '';
    }


    getItemStatusString(status: boolean = true): string {
        switch (status) {
            case false:
                return 'Pasif';
            case true:
                return 'Aktif';

        }
        return '';
    }






}
