// Angular
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../../../core/_base/layout';
// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';


import { CrmService } from '../../../../../core/crm/_services/crm.service';



@Component({
    selector: 'kt-widget14',
    templateUrl: './widget14.component.html',
    styleUrls: ['./widget14.component.scss'],
})
export class Widget14Component implements OnInit {
    [x: string]: any;
    // Public properties
    @Input() title: string;
    @Input() desc: string;
    @Input() data: { labels: string[]; datasets: any[] };
    @ViewChild('chart', { static: true }) chart: ElementRef;


    constructor(private layoutConfigService: LayoutConfigService, private crmService: CrmService) {
    }


    ngOnInit() {
        // if (!this.data) {

        //     this.reportsService.getLast7DaysAddedCompanyCount()
        //         .subscribe(response => {
        //             // this.citiesResult = response.value;
        //             // console.log(response);
        //             let _labels: string[] = [];
        //             let _data: any[] = [];

        //             response.value.forEach(element => {
        //                 _labels.push(element.name);
        //                 _data.push(Number(element.value));
        //             });

        //             this.data = {
        //                 labels: _labels,
        //                 datasets: [
        //                     {
        //                         // label: 'dataset 1',
        //                         backgroundColor: this.layoutConfigService.getConfig('colors.state.success'),
        //                         data: _data
        //                     }
        //                 ]
        //             };
        //             this.initChartJS();
        //         });


        // }


    }

    /** Init chart */
    initChartJS() {
        // For more information about the chartjs, visit this link
        // https://www.chartjs.org/docs/latest/getting-started/usage.html

        const chart = new Chart(this.chart.nativeElement, {
            type: 'bar',
            data: this.data,
            options: {
                title: {
                    display: false,
                },
                tooltips: {
                    intersect: false,
                    mode: 'nearest',
                    xPadding: 10,
                    yPadding: 10,
                    caretPadding: 10
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                barRadius: 4,
                scales: {
                    xAxes: [{
                        display: false,
                        gridLines: false,
                        stacked: true
                    }],
                    yAxes: [{
                        display: false,
                        stacked: true,
                        gridLines: false
                    }]
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                }
            }
        });
    }
}
