
/// CRM
// SERVICES
export { CrmService } from './_services/crm.service';

// DATA SOURCERS
export { CrmDataSource } from './_data-sources/crm.datasource';

// ACTIONS

export {
    CrmCreated,
    CrmUpdated,
    OneCrmDeleted,
    ManyCrmDeleted,
    CrmOnServerCreated,
    CrmPageLoaded,
    CrmPageCancelled,
    CrmPageToggleLoading,
    CrmPageRequested,
    CrmActionToggleLoading
} from './_actions/crm.actions';

// EFFECTS
export { CrmEffects } from './_effects/crm.effects';

// REDUCERS
export { crmReducer } from './_reducers/crm.reducers';

// SELECTORS

export {
    selectCrmById,
    selectCrmPageLoading,
    selectLastCreatedCrmId,
    selectCrmInStore,
    selectCrmActionLoading,
    selectCrmShowInitWaitingMessage
} from './_selectors/crm.selectors';



// MODELS
export { CrmModel } from './_models/crm.model';

/// users
// SERVICES
export { usersService } from './_services/users.service';

// ACTIONS
export {
    usersPageToggleLoading,
    usersActionToggleLoading
} from './_actions/users.actions';

// MODELS
export { usersModel } from './_models/users/users.model';
export { sitepagesModel } from './_models/sitepages/sitepages.model';
export { sitepagerowsModel } from './_models/sitepagerows/sitepagerows.model';
export { sitewidgetsModel } from './_models/sitewidgets/sitewidgets.model';
export { sitewidgetdetailsModel } from './_models/sitewidgetdetails/sitewidgetdetails.model';

export { processayareviModel } from './_models/process/processayarevi.model';
export { processmusteriModel } from './_models/process/processmusteri.model';
export { ayarevicarilerModel } from './_models/process/ayarevicariler.model';

export { settingsModel } from './_models/users/settings.model';

export { processayarevireportsModel } from './_models/process/processayarevireports.model';



//SERVİCE
export { sitepagesService } from './_services/sitepages.service';
export { sitepagerowsService } from './_services/sitepagerows.service';
export { sitewidgetsService } from './_services/sitewidgets.service';
export { sitewidgetdetailsService } from './_services/sitewidgetdetails.service';

export { processService } from './_services/process.service';
export { ayarevicarilerService } from './_services/ayarevicariler.service';


export {
    sitepagesPageToggleLoading,
    sitepagesActionToggleLoading
} from './_actions/sitepages.actions';


// MODELS


