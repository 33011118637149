import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class sitepagesModel extends BaseModel {
    id: number;
    recordid: number;
    status: number;
    isdeleted: boolean;
    name: string;
    description: string;
    type: number;
    pageorder: number;
   
    clear() {
        this.id = 0;
        this.recordid = 0;
        this.status = 1;
        this.isdeleted = false;
        this.name = '';
        this.type = 1;
        this.pageorder = 99;
        this.description = '';
    }
}
