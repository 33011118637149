// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, EventEmitter } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { sitewidgetsModel, sitewidgetsService } from '../../../../../../core/crm';
import { sitewidgetsQueryParamsModel } from '../../../../../../core/crm/_models/sitewidgets/sitewidgets-query-params.model';
import { Output } from '@angular/core';
import { sitewidgetsEditDialogComponent } from '../sitewidgets-edit/sitewidgets-edit.dialog.component';
import { TranslateService } from '@ngx-translate/core';





@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitewidgets',
    templateUrl: './sitewidgets.component.html',
    styleUrls: ['./sitewidgets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class sitewidgetsComponent implements OnInit, OnDestroy {
    @Input() PagerowId: number = 0;
    @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();

    // Table fields
    displayedColumns = ['recordid', 'widgetname', 'size', 'widgetorder',  'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    filterStatus = '-1';
    ProcessStatus = '2';
    lcp6 = '0';

    // Selection
    selection = new SelectionModel<sitewidgetsModel>(true, []);
    sitewidgetResult: sitewidgetsModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitewidgetsService: sitewidgetsService,
        private cdRef: ChangeDetectorRef
    ) { }


    ngOnInit() {
        this.loadsitewidgets();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }


    loadsitewidgets() {
        this.loading = true;

        // this.sitepagesService.findsitepages(queryParams).subscribe(res => {
        this.sitewidgetsService.getsitewidgetsByPageRowId(this.PagerowId).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value);
                this.sitewidgetResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                //this.paginatorTotal = this.paginator.pageSize;
                this.sitewidgetResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });

        this.selection.clear();

    }

    editsitewidget(users: sitewidgetsModel) {
        let saveMessageTranslateParam = 'SYSTEM.USERS.EDIT.';
        saveMessageTranslateParam += users.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = users.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(sitewidgetsEditDialogComponent, { data: { users, itemcount:this.sitewidgetResult.length } });
        dialogRef.afterClosed().subscribe(res => {
            if (res !== undefined) {
                if (res.status === 0) {
                    this.layoutUtilsService.showActionNotification(saveMessage, messageType);
                    this.loadsitewidgets();
                } else {
                    this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Kullanıcı Oluşturulamadı.'), '', 'Tamam');
                }
            }


        });
    }

    deletesitepages(item: sitewidgetsModel) {
        const title: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.TITLE');
        const description: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.WAIT_DESCRIPTION');
        const deleteMessage = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.loadsitewidgets();
            this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
        });
    }

    addnewitem() {
        const newsitepages = new sitewidgetsModel();
        newsitepages.clear(); // Set all defaults fields
        newsitepages.recordid = 0;
        newsitepages.pagerowid = this.PagerowId;
        this.editsitewidget(newsitepages);
    }


    getWidgetNameString(item: string = ''): string {
        switch (item) {
            case 'contactdetail':
                return 'İletişim Bilgileri';
            case 'image':
                return 'Resim';
            case 'liveprice':
                return 'Canlı Piyasalar';
            case 'livepriceScroll':
                return 'Canlı Piyasalar Kayan';
            case 'map':
                return 'Harita';
            case 'pagetitle':
                return 'Sayfa Başlığı';
            case 'slider':
                return 'Slider';
            case 'textcontent':
                return 'Yazı Komponenti';
            case 'timeline':
                return 'Zaman Çizelgesi';
            case 'tradingview':
                return 'Trading View Komponenti';
            case 'youtubeembed':
                return 'YouTube Video';
        }
        return '';
    }
    getSizeString(item: string = ''): string {
        switch (item) {
            case 'col-md-1 col-sm-12':
                return '%8';
            case 'col-md-2 col-sm-12':
                return '%16';
            case 'col-md-3 col-sm-12':
                return '%25';
            case 'col-md-4 col-sm-12':
                return '%33';
            case 'col-md-5 col-sm-12':
                return '%41';
            case 'col-md-6 col-sm-12':
                return '%50';
            case 'col-md-7 col-sm-12':
                return '%58';
            case 'col-md-8 col-sm-12':
                return '%66';
            case 'col-md-9 col-sm-12':
                return '%75';
            case 'col-md-10 col-sm-12':
                return '%83';
            case 'col-md-11 col-sm-12':
                return '%91';
            case 'col-12':
                return '%100';
        }
        return '';
    }




    getItemCssClassByStatus(status: boolean = true): string {
        switch (status) {
            case false:
                return 'danger';
            case true:
                return 'success';
        }
        return '';
    }


    getItemStatusString(status: boolean = true): string {
        switch (status) {
            case false:
                return 'Pasif';
            case true:
                return 'Aktif';

        }
        return '';
    }

    goback() {

        this.pageEvent.emit({
            step: 2,
            Id: this.PagerowId
        });
    }

    detailpage(sitewidget: sitewidgetsModel) {
        this.pageEvent.emit({
            step: 4,
            Id: sitewidget.recordid,
            WidgetName : sitewidget.widgetname
        });
    }







}
