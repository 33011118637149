import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class settingsModel extends BaseModel {
    [x: string]: any;
    id: number;
    recordid: number;
    status: number;
    refid: string;
    isdeleted: boolean;
    http: string;
    domain: string;
    userid : number;
    headerlogo: string;
    headerlogowidth: string;
    headerlogoheight: string;
    favicon: string;
    title: string;
    headertype: number;
    headerbgcolor: string;
    headerbgimg: string;
    headercontentsize: string;
    headerheight
    headermenualign
    headermenutexttransform
    headermenufontsize
    headermenucolor
    headermenuhovercolor
    footertype: number;
    footerbgcolor: string;
    footerbgimg: string;
    footercontentsize: string;
    footermenutexttransform: string;
    footerheight: string;
    footerlogo: string;
    footerlogowidth: string;
    footerlogoheight: string;
    footermenufontsize: string;
    footermenucolor: string;
    footermenuhovercolor: string;
    phone: string;
    mobilephone: string;
    email: string;
    address: string;
    lat: string;
    lon: string;



    clear() {
        this.id = 0;
        this.recordid = 0;
        this.status = 1;
        this.refid = '';
        this.isdeleted = false;
        this.http = '';
        this.domain = '';
        this.userid= 0;
        this.headerlogo= '';
        this.headerlogowidth= '';
        this.headerlogoheight= '';
        this.favicon= '';
        this.title= '';
        this.headertype= 0;
        this.headerbgcolor= '';
        this.headerbgimg= '';
        this.headercontentsize= '';
        this.headerheight= '';
        this.headermenualign= '';
        this.headermenutexttransform= '';
        this.headermenufontsize= '';
        this.headermenucolor= '';
        this.headermenuhovercolor= '';
        this.footertype= 0;
        this.footerbgcolor= '';
        this.footerbgimg= '';
        this.footercontentsize= '';
        this.footermenutexttransform= '';
        this.footerheight= '';
        this.footerlogo= '';
        this.footerlogowidth= '';
        this.footerlogoheight= '';
        this.footermenufontsize= '';
        this.footermenucolor= '';
        this.footermenuhovercolor= '';
        this.phone= '';
        this.mobilephone= '';
        this.email= '';
        this.address= '';
        this.lat= '';
        this.lon= '';
        

    }
}
