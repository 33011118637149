// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { processmusteriModel, processService } from '../../../../../../core/crm';
// import { processmusteriQueryParamsModel } from '../../../../../../core/crm/_models/process/processmusteri-query-params.model';

// Components

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-processmusterimain',
    templateUrl: './processmusterimain.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class processmusteriMainComponent implements OnInit, OnDestroy {
    // Table fields

    
    selectedStep = 1;
    selectedUserId = 0;
    pageId = 0;
    pageRowsId = 0;
    pageWidgetId = 0;
    pageWidgetDetailId = 0;
    widgetName = '';
    selectedusersettings: any = null;

    // Selection
    // selection = new SelectionModel<processmusteriModel>(true, []);
    // processmusteriResult: processmusteriModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private processmusteriService: processService,
        private cdRef: ChangeDetectorRef
    ) { }

    receivePageEvent(event: any) {
        // console.log(event.step)
        // const index = this.subjects
        //   .map((subject) => {
        //     return subject.code;
        //   })
        this.selectedStep = 2;
        this.pageId = event.Id;
        this.selectedUserId = event.userid;
        this.selectedusersettings = event.usersettings;

    }



    receivePageRowsEvent(event: any) {
        // console.log(event.step)
        if (event.step == 1) {
            this.selectedStep = 1;

        }
        else {
            this.selectedStep = 3;
            this.pageRowsId = event.Id;
        }
    }
    receiveWidgetEvent(event: any) {
        // console.log(event.step)
        if (event.step == 2) {
            this.selectedStep = 2;
        }
        else {
            this.selectedStep = 4;
            this.pageWidgetId = event.Id;
            this.widgetName = event.WidgetName;
        }
    }
    receiveWidgetDetailEvent(event: any) {
        // console.log(event.step)
        this.selectedStep = 3;
    }

    ngOnInit() {

    }

    ngOnDestroy() {
       // this.subscriptions.forEach(el => el.unsubscribe());
       // this.subscriptions.forEach(el => el.unsubscribe());
    }
}
