// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
import { currentUser, Logout, User } from '../../../../../core/auth';

@Component({
    selector: 'kt-user-profile3',
    templateUrl: './user-profile3.component.html',
})
export class UserProfile3Component implements OnInit {
    // Public properties
    user$: Observable<User>;

    @Input() avatar: boolean = true;
    @Input() greeting: boolean = true;
    @Input() badge: boolean;
    @Input() icon: boolean;

    constructor(private store: Store<AppState>) {
    }

    ngOnInit(): void {
        this.user$ = this.store.pipe(select(currentUser));
    }

    logout() {
        this.store.dispatch(new Logout());
    }
}
