// Angular
import { Component, Input, OnInit } from '@angular/core';
// Lodash
import { shuffle } from 'lodash';

import { CrmService } from '../../../../../core/crm/_services/crm.service';


export interface Widget1Data {
    title: string;
    desc: string;
    value: string;
    valueClass?: string;
}

@Component({
    selector: 'kt-widget1',
    templateUrl: './widget1.component.html',
    styleUrls: ['./widget1.component.scss']
})
export class Widget1Component implements OnInit {
    // Public properties
    @Input() data: Widget1Data[];

    constructor(private crmService: CrmService) { }

    ngOnInit() {
        // if (!this.data) {

        //     this.reportsService.getCountWaitAcceptRejectCompany()
        //         .subscribe(response => {

        //             let _data = response.value[0];

        //             this.data = shuffle([
        //                 {
        //                     title: 'Rededilen İşletme',
        //                     desc: 'Rededilen işletme sayısı',
        //                     value: _data.type + ' adet',
        //                     valueClass: 'kt-font-danger'
        //                 }, {
        //                     title: 'Onay Bekleyen İşletme',
        //                     desc: 'Onay bekleyen işetle sayısı',
        //                     value: _data.name + ' adet',
        //                     valueClass: 'kt-font-warning'
        //                 }, {
        //                     title: 'Toplam Onaylı İşletme',
        //                     desc: 'Toplam onaylı işletme sayısı',
        //                     value: _data.value + ' adet',
        //                     valueClass: 'kt-font-success'
        //                 }
        //             ]);
        //         });

         
        // }
    }

}
