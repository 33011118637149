// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { sitepagesModel, sitepagesService, usersModel, usersService } from '../../../../../../core/crm';
import { sitepagesQueryParamsModel } from '../../../../../../core/crm/_models/sitepages/sitepages-query-params.model';





@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitepages',
    templateUrl: './sitepages.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class sitepagesComponent implements OnInit, OnDestroy {
    @Input() UserId: number;
    @Output() pageEvent: EventEmitter<any> = new EventEmitter<any>();


    // Table fields
    displayedColumns = ['recordid', 'description', 'status', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    // filterStatus = '-1';
    filteruserId = 1;
    selectedusersettings: any = null;

    // Selection
    selection = new SelectionModel<sitepagesModel>(true, []);
    sitepagesResult: sitepagesModel[] = [];
    usersResult: usersModel[] = [];

    loading = false;
    paginatorTotal = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitepagesService: sitepagesService,
        private userService: usersService,
        private cdRef: ChangeDetectorRef
    ) { }


    ngOnInit() {

        this.loadUserList();

    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    loadUserList() {
        this.userService.getAllusersByUserid().subscribe(res => {
            // this.sitepagesService.getsitepagesByUserId(this.filteruserId).subscribe(res => {

            const data = JSON.parse(res.value);
            this.usersResult = data;

            this.filteruserId = this.UserId;
            this.loadsitepages();
            // this.loadStatesList();
            this.cdRef.detectChanges();
        });

    }

    loadsitepages() {
        this.loading = true;
        // this.selection.clear();
        // const queryParams = new sitepagesQueryParamsModel(
        //     this.searchInput.nativeElement.value,
        //     this.sort.direction,
        //     this.sort.active,
        //     this.paginator.pageIndex,
        //     this.paginator.pageSize,
        //     Number(this.filterStatus),
        //     Number(this.filterUsers)
        // );

        this.sitepagesService.getsitepagesByUserId(this.filteruserId).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value);
                this.sitepagesResult = data;
                // this.selectedusersettings = this.sitepagesResult;
                //this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                //   this.sitepagesResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                // this.paginatorTotal = this.paginator.pageSize;
                this.sitepagesResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });
        this.userService.getusersettingsByuserid(this.filteruserId).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                this.selectedusersettings = data[0];
            }
        });

        this.selection.clear();

    }



    deletesitepages(item: sitepagesModel) {
        const title: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.TITLE');
        const description: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.WAIT_DESCRIPTION');
        const deleteMessage = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.loadsitepages();
            this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
        });
    }

    addsitepages() {
        const newsitepages = new sitepagesModel();
        newsitepages.clear(); // Set all defaults fields
        //this.editsitepages(newsitepages);
    }

    pagerows(sitepages: sitepagesModel) {


        this.pageEvent.emit({
            step: 2,
            Id: sitepages.recordid,
            userid: this.filteruserId,
            usersettings: this.selectedusersettings
        });
    }





    getItemCssClassByStatus(status: boolean = true): string {
        switch (status) {
            case false:
                return 'danger';

            case true:
                return 'success';
        }
        return '';
    }


    getItemStatusString(status: boolean = true): string {
        switch (status) {
            case false:
                return 'Pasif';
            case true:
                return 'Aktif';

        }
        return '';
    }






}
