export  class QueryParamsModel {
    // fields
    searchtext: string;
    sortOrder: string; // asc || desc
    sortField: string;
    pageNumber: number;
    pageSize: number;

    // constructor overrides
    constructor() {
    }
}
