// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { CrmActions, CrmActionTypes } from '../_actions/crm.actions';
// Models
import { CrmModel } from '../_models/crm.model';
import { CrmQueryParamsModel } from '../_models/crm-query-params.model';
import { isUndefined } from 'util';


export interface CrmState extends EntityState<CrmModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedCrmId: number;
    lastQuery: CrmQueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CrmModel> = createEntityAdapter<CrmModel>();

export const initialCrmState: CrmState = adapter.getInitialState({
    crmForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedCrmId: undefined,
    lastQuery: new CrmQueryParamsModel(),
    showInitWaitingMessage: true
});

export function crmReducer(state = initialCrmState, action: CrmActions): CrmState {
    switch (action.type) {
        case CrmActionTypes.CrmPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCrmId: undefined
            };
        }
        case CrmActionTypes.CrmActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case CrmActionTypes.CrmOnServerCreated: return {
            ...state
        };
        case CrmActionTypes.CrmCreated: return adapter.addOne(action.payload.crm, {
            ...state, lastCreatedCrmId: action.payload.crm.recordId
        });
        case CrmActionTypes.CrmUpdated: return adapter.updateOne(action.payload.partialCrm, state);
        case CrmActionTypes.CrmStatusUpdated: {
            const _partialCrm: Update<CrmModel>[] = [];
            // tslint:disable-next-line:prefer-const
            for (let i = 0; i < action.payload.crm.length; i++) {
                _partialCrm.push({
                    id: action.payload.crm[i].recordId,
                    changes: {
                        status: action.payload.status
                    }
                });
            }
            return adapter.updateMany(_partialCrm, state);
        }
        case CrmActionTypes.OneCrmDeleted: return adapter.removeOne(action.payload.id, state);
        case CrmActionTypes.ManyCrmDeleted: return adapter.removeMany(action.payload.ids, state);
        case CrmActionTypes.CrmPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new CrmQueryParamsModel()
            };
        }
        case CrmActionTypes.CrmPageLoaded: {
            return adapter.addMany(action.payload.crm, {
                ...initialCrmState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getCrmState = createFeatureSelector<CrmModel>('crm');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
