// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { ayarevicarilerModel, processService } from '../../../../../../core/crm';
// import { ayarevicarilerQueryParamsModel } from '../../../../../../core/crm/_models/process/ayarevicariler-query-params.model';

// Components

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-ayarevicarilermain',
    templateUrl: './ayarevicarilermain.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
// tslint:disable-next-line: class-name
export class ayarevicarilerMainComponent implements OnInit, OnDestroy {
    // Table fields

  

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private ayarevicarilerService: processService,
        private cdRef: ChangeDetectorRef
    ) { }

    
    ngOnInit() {

    }

    ngOnDestroy() {
       // this.subscriptions.forEach(el => el.unsubscribe());
       // this.subscriptions.forEach(el => el.unsubscribe());
    }
}
