// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
// Models
import { processayareviModel } from '../_models/process/processayarevi.model';
import { processmusteriModel } from '../_models/process/processmusteri.model';
import { ayarevicarilerModel } from '../_models/process/ayarevicariler.model';

import { BaseClass } from '../../../BaseClass';
import { map } from 'lodash';
import { ResponseBaseObject } from '../../auth/_models/ResponseBaseObject.model';
import { catchError } from 'rxjs/operators';
import { processayareviQueryParamsModel } from '../_models/process/processayarevi-query-params.model';
import { environment } from '../../../../environments/environment';
import { processmusteriQueryParamsModel } from '../_models/process/processmusteri-query-params.model';
import { ayarevicarilerQueryParamsModel } from '../_models/process/ayarevicariler-query-params.model';



@Injectable()
export class ayarevicarilerService {
    API_USERS_URL = '';
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, public baseClass: BaseClass) {
        this.API_USERS_URL = this.baseClass.apiUrl + 'users';
    }

    // CREATE =>  POST: add a new process to the server
    createprocess(process: ayarevicarilerModel, processId: number): Observable<any> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/update';
        const data: String = JSON.stringify(process);

        return this.http.put(url, data, options);

    }

    // UPDATE => PUT: update the process on the server
    updateprocess(process: ayarevicarilerModel): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/update';
        const data: String = JSON.stringify(process);

        return this.http.put(url, data, options);
    }

    // READ
    getAllprocess(): Observable<any> {
        return this.http.get<any>(this.API_USERS_URL+"/getallpages");
    }

    getprocessById(processId: number): Observable<ayarevicarilerModel> {
        return this.http.get<ayarevicarilerModel>(this.API_USERS_URL + `/${processId}`);
    }
    getcariByUserId(userId: number): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + `/getbyuserid/${userId}`);
    }

   
    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    findcariler(queryParams: ayarevicarilerQueryParamsModel): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headersParams = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });

        const httpParams = new HttpParams()
            .set('status', queryParams.Status.toString())
            .set('searchtext', queryParams.searchtext)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())

        const url = this.API_USERS_URL + '/ForGridAyarEviCustomers';
        const options = { headers: headersParams, params: httpParams };
        return this.http.get<ResponseBaseObject>(url, options);
    }
    // findprocessmusteri(queryParams: processmusteriQueryParamsModel): Observable<ResponseBaseObject> {

    //     const userToken = localStorage.getItem(environment.authTokenKey);
    //     const headersParams = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });

    //     const httpParams = new HttpParams()
    //         .set('status', queryParams.Status.toString())
    //         .set('searchtext', queryParams.searchtext)
    //         .set('sortOrder', queryParams.sortOrder)
    //         .set('sortField', queryParams.sortField)
    //         .set('pageNumber', queryParams.pageNumber.toString())
    //         .set('pageSize', queryParams.pageSize.toString())
    //         .set('companyid', queryParams.companyid.toString())
            
            

    //     const url = this.API_USERS_URL + '/ForMusteriGrid';
    //     const options = { headers: headersParams, params: httpParams };
    //     return this.http.get<ResponseBaseObject>(url, options);
    // }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    
  
    // UPDATE Status
    updateStatusForcariler(process: ayarevicarilerModel[], status: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {
            processForUpdate: process,
            newStatus: status
        };
        const url = this.API_USERS_URL + '/update';
        return this.http.put(url, body, { headers: httpHeaders });
    }

    getAllRoles(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + '/Roles');
    }
}
