// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
// Models
import { CrmModel } from '../_models/crm.model';

import { BaseClass } from '../../../BaseClass';
import { map } from 'lodash';
import { ResponseBaseObject } from '../../auth/_models/ResponseBaseObject.model';
import { catchError } from 'rxjs/operators';
import { CrmQueryParamsModel } from '../_models/crm-query-params.model';




@Injectable()
export class CrmService {
    API_USERS_URL = '';
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, public baseClass: BaseClass) {
        this.API_USERS_URL = this.baseClass.apiUrl + 'crm';
    }

    // CREATE =>  POST: add a new crm to the server
    createCrm(crm: CrmModel, CrmId: number): Observable<ResponseBaseObject> {
        // Note: Add headers if needed (tokens/bearer)
        const url = this.API_USERS_URL + '/createCrm';
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<ResponseBaseObject>(url, crm, { headers: httpHeaders });
    }

    // READ
    getAllCrm(): Observable<CrmModel[]> {
        return this.http.get<CrmModel[]>(this.API_USERS_URL);
    }

    getCrmById(crmId: number): Observable<CrmModel> {
        return this.http.get<CrmModel>(this.API_USERS_URL + `/${crmId}`);
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    findCrm(queryParams: CrmQueryParamsModel): Observable<QueryResultsModel> {

        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = new HttpParams()
            .set('status', queryParams.Status.toString())
            .set('searchtext', queryParams.searchtext)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString());

        const url = this.API_USERS_URL + '/ForGrid';
        return this.http.get<QueryResultsModel>(url, {
            headers: httpHeaders,
            params: httpParams
        });
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    // UPDATE => PUT: update the crm on the server
    updateCrm(crm: CrmModel, CrmId: number): Observable<any> {
        const url = this.API_USERS_URL + '/updateCrm';
        const httpHeader = this.httpUtils.getHTTPHeaders();
        return this.http.post(url, crm, { headers: httpHeader });
    }

    // UPDATE Status
    updateStatusForCrm(crm: CrmModel[], status: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {
            crmForUpdate: crm,
            newStatus: status
        };
        const url = this.API_USERS_URL + '/updateCrm';
        return this.http.put(url, body, { headers: httpHeaders });
    }

    getAllRoles(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + '/Roles');
    }
}
