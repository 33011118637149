export class QueryResultsModel {
    // fields
    items: any[];
    value: any;
    totalCount: number;
    errorMessage: string;

    constructor(_items: any[] = [], _value: any = [], _totalCount: number = 0, _errorMessage: string = '') {
        this.items = _items;
        this.value = _value;
        this.totalCount = _totalCount;
    }
}
