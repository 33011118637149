// NGRX
import { Action } from '@ngrx/store';

export enum sitepagesActionTypes {
    sitepagesPageToggleLoading = '[sitepages] sitepages Page Toggle Loading',
    sitepagesActionToggleLoading = '[sitepages] sitepages Action Toggle Loading'
}
export class sitepagesPageToggleLoading implements Action {
    readonly type = sitepagesActionTypes.sitepagesPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class sitepagesActionToggleLoading implements Action {
    readonly type = sitepagesActionTypes.sitepagesActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type sitepagesActions = sitepagesPageToggleLoading | sitepagesActionToggleLoading;
