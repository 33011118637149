// Angular
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
// Material

import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
// RXJS
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';


// Translate Module
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store, ActionsSubject } from '@ngrx/store';
import { AppState } from '../../../../../../core/reducers';
// CRUD

import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud';
// Services and Models
import { ayarevicarilerModel, processayareviModel, processService, usersModel, usersService } from '../../../../../../core/crm';
import { processayareviQueryParamsModel } from '../../../../../../core/crm/_models/process/processayarevi-query-params.model';
import { processayareviEditDialogComponent } from '../processayarevi-edit/processayarevi-edit.dialog.component';
import { processayareviLastValueDialogComponent } from '../processayarevi-lastvalue/processayarevi-lastvalue.dialog.component';

import { FormGroup, FormControl } from '@angular/forms';

//import {  MAT_DATE_LOCALE } from '@angular/material/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { processayarevicarilerlistComponent } from '../processayarevicarilerlist/processayarevicarilerlist.component';
import { ayarevicarilerQueryParamsModel } from '../../../../../../core/crm/_models/process/ayarevicariler-query-params.model';

import { ayarevicarilerService } from '../../../../../../../../src/app/core/crm/_services/ayarevicariler.service';


export const MY_FORMATS = {
    parse: {
        dateInput: 'DD.MM.YYYY',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-processayarevilist',
    templateUrl: './processayarevilist.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
       
        { provide: MAT_DATE_LOCALE, useValue: 'tr-TR' },
      //  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },

       
    ],

})

// tslint:disable-next-line: class-name
export class processayarevilistComponent implements OnInit, OnDestroy {
    @Input() UserId: number;

    // Table fields
    // displayedColumns = ['recordid','companyid','companyuserid','userid','raporno','girismiktar','cikismiktar','giriszamani','cikiszamani','aumilyem','agmilyem','pltmilyem','pldmilyem','ayareviraporno', 'actions'];

    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });



    displayedColumns = ['customertitle', 'companyusername', 'raporno', 'ayareviraporno', 'processtime', 'maininformation', 'milyeminformation', 'status', 'actions'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('sort1', { static: true }) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    @ViewChild('searchInputcari', { static: true }) searchInputcari: ElementRef;

    // filterStatus = '-1';
    filterType = -1;
    selectedusersettings: any = null;

    // Selection
    selection = new SelectionModel<processayareviModel>(true, []);
    dataResult: processayareviModel[] = [];
    usersResult: usersModel[] = [];
    dropdownResult: ayarevicarilerModel[] = [];

    loading = false;
    paginatorTotal = 0;

     selectedusersId ='';

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private processService: processService,
        private userService: usersService,
        private cdRef: ChangeDetectorRef,
        private dateAdapter: DateAdapter<Date>,
        private ayarevicarilerService: ayarevicarilerService,
    ) {
        this.dateAdapter.setLocale('tr-TR');

     }


    ngOnInit() {

        let date: Date;
        date = new Date();
        date.setDate(date.getDate() + 1);
        this.range.controls['end'].setValue(date.toISOString());
        date.setDate(date.getDate() - 4);
        this.range.controls['start'].setValue(date.toISOString());


        this.paginator._intl.itemsPerPageLabel = 'Sayfa başına kayıt sayısı:';
        this.paginator._intl.nextPageLabel = 'Sonraki Sayfa';
        this.paginator._intl.lastPageLabel = 'Son Sayfa';
        this.paginator._intl.previousPageLabel = 'Önceki Sayfa';
        this.paginator._intl.firstPageLabel = 'İlk Sayfa';
        // tslint:disable-next-line: max-line-length
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => { if (length === 0 || pageSize === 0) { return `0 / ${length}`; } length = Math.max(length, 0); const startIndex = page * pageSize; const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} / ${length}`; };

        // If the devices changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (
            this.paginator.pageIndex = 0,
            this.loadList()
        ));
        this.subscriptions.push(sortSubscription);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        )
            .subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.loadList();
        this.loadListdropdown();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    loadUserList() {
        this.userService.getAllusersByUserid().subscribe(res => {
            // this.processayarevilistService.getprocessayarevilistByUserId(this.filteruserId).subscribe(res => {

            const data = JSON.parse(res.value);
            this.usersResult = data;

            //this.filteruserId = this.UserId;
            this.loadList();
            // this.loadStatesList();
            this.cdRef.detectChanges();
        });

    }

    loadList() {
      //  const controls = this.range.controls;

       // console.log(this.range.get('start').value);
        this.loading = true;
        this.selection.clear();
        var companyid = 0;
        var userid = 0;
        var sdate =  this.dateFormatdmy( new Date(  this.range.get('start').value ));
        var edate =  this.dateFormatdmy( new Date(  this.range.get('end').value ));



        const queryParams = new processayareviQueryParamsModel(
            this.searchInput.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filterType,
            this.dateFormatdmy( new Date(  this.range.get('start').value )),  //controls.start.value,
            this.dateFormatdmy( new Date(  this.range.get('end').value )),  //controls.start.value,
            this.selectedusersId,
        );

        this.processService.findprocessayarevi(queryParams).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                this.dataResult = data;
                // this.selectedusersettings = this.processayarevilistResult;
                //this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                //   this.processayarevilistResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                // this.paginatorTotal = this.paginator.pageSize;
                this.dataResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });



        this.selection.clear();

    }

    dateFormatdmy(date: Date ): string {
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();
		if (date) {
			return `${day}.${month}.${year} 00:00:00.000`;
		}

		return '';
	}


    filterConfiguration(): any {
        const filter: any = {};
        const searchText: string = this.searchInput.nativeElement.value;

        filter.Name = searchText;
        if (!searchText) {
            return filter;
        }

        filter.Name = searchText;
        filter.Email = searchText;
        return filter;
    }



    deleteprocessayarevilist(item: processayareviModel) {
        const title: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.TITLE');
        const description: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.DESCRIPTION');
        const waitDesciption: string = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.WAIT_DESCRIPTION');
        const deleteMessage = this.translate.instant('LAWOFFICE.COMPANIES.DELETE_COMPANY_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(title, description, waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.loadList();
            this.layoutUtilsService.showActionNotification(deleteMessage, MessageType.Delete);
        });
    }

    addnew() {
        const newprocessayarevilist = new processayareviModel();
        newprocessayarevilist.clear(); // Set all defaults fields
        this.edit(newprocessayarevilist);
    }
    edit(editmodel: processayareviModel) {
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam += editmodel.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = editmodel.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(processayareviEditDialogComponent, { data: { editmodel } });
        dialogRef.afterClosed().subscribe(res => {
            if (res !== undefined)
                if (res.status === 0) {
                    this.layoutUtilsService.showActionNotification("Yeni kayıt başarı ile güncellendi.", messageType);
                    //this.loadList();
                } else {
                    this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', res.statusMessage.replace('Object reference not set to an instance of an object.', 'Cihaz sistemde tanımlıdır.'), '', 'Tamam');
                }
                this.loadList();
        });
  
    }



    pagerows(processayarevilist: processayareviModel) {


        // this.pageEvent.emit({
        //     step: 2,
        //     Id: processayarevilist.recordid,
        //     //userid: this.filteruserId,
        //     usersettings: this.selectedusersettings
        // });
    }

    detailinf(model: processayareviModel) {
        let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        saveMessageTranslateParam += model.recordid > 0 ? 'UPDATE_MESSAGE' : 'ADD_MESSAGE';
        const saveMessage = this.translate.instant(saveMessageTranslateParam);
        const messageType = model.recordid > 0 ? MessageType.Update : MessageType.Create;
        const dialogRef = this.dialog.open(processayareviLastValueDialogComponent, { data: { model } });
        dialogRef.afterClosed().subscribe(res => {

            if (!res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(saveMessage, messageType);
        });
    }

    getcarilist() {
        // let saveMessageTranslateParam = 'LAWOFFICE.COMPANIES.EDIT.';
        // saveMessageTranslateParam +=  'UPDATE_MESSAGE' ;
        // const saveMessage = this.translate.instant(saveMessageTranslateParam);
        // const messageType =  MessageType.Update ;
        const dialogRef = this.dialog.open(processayarevicarilerlistComponent, { data: {  } });
        dialogRef.afterClosed().subscribe(res => {

            if (!res.data) {
                return;
            }

            this.searchInputcari.nativeElement.value = res.data.code;
            //this.layoutUtilsService.showActionNotification(saveMessage, messageType);
        this.loadList();

        });
    }

    loadListdropdown() {
        this.loading = true;
        this.selection.clear();
        var companyid = 0;
        var userid = 0;
        const queryParams = new ayarevicarilerQueryParamsModel(
            "",
            "",
            "",
            0,
            9999,
           1

        );

        this.ayarevicarilerService.findcariler(queryParams).subscribe(res => {

            if (res.status === 0) {
                const data = JSON.parse(res.value.json);
                 this.dropdownResult = data;
                // this.selectedusersettings = this.ayarevicarilerlistResult;
                //this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                //   this.ayarevicarilerlistResult = data;
                this.loading = false;
            } else if (res.status === 4) {
                // this.paginatorTotal = this.paginator.pageSize;
                this.dropdownResult = [];

            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });



        this.selection.clear();

    }


    clearUser() {
        this.selectedusersId = '';
    }
    createpdf(editmodel: processayareviModel) {
        this.processService.getcreatepdfById(editmodel.recordid).subscribe(res => {

            if (res.status === 0) {
                //  const data = JSON.parse(res);
                // this.dataResult = null;
                // this.selectedusersettings = this.processayarevilistResult;
                //this.paginatorTotal = data.length > 0 ? res.value.rowCount : 0,
                //   this.processayarevilistResult = data;
                //this.loading = false;
                this.loadList();
            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }
            this.loading = false;
            this.cdRef.detectChanges();
        });
    }



    getItemCssClassByStatus(item: processayareviModel): string {
        if (item.cikiszamani)
            return 'success';
        else
            return 'danger';



    }


    getItemStatusString(item: processayareviModel): string {
        if (item.cikiszamani)
            return 'Raporlu';
        else
            return 'Raporsuz';


    }

    formatnumber(item: string) {

        return Number(item);
    }






}
