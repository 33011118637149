import { BaseModel } from '../../_base/crud';

export class CrmModel extends BaseModel {
    id: number;
    userId: number;
    recordId: number;
    status: number;
    refId: string;
    isDeleted: boolean;

    lastContractNo: number;
    ufeTufe: number;

    clear() {
        this.id = 0;
        this.userId = 0;
        this.recordId = 0;
        this.status = 1;
        this.refId = '';
        this.isDeleted = false;

        this.lastContractNo = 0;
        this.ufeTufe = 0;

    }
}
