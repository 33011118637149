// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

// Models
import { CrmModel } from '../_models/crm.model';
import { CrmQueryParamsModel } from '../_models/crm-query-params.model';

export enum CrmActionTypes {
    CrmOnServerCreated = '[Edit Crm Dialog] Crm On Server Created',
    CrmCreated = '[Edit Crm Dialog] Crm Created',
    CrmUpdated = '[Edit Crm Dialog] Crm Updated',
    CrmStatusUpdated = '[Crm List Page] Crm Status Updated',
    OneCrmDeleted = '[Crm List Page] One Crm Deleted',
    ManyCrmDeleted = '[Crm List Page] Many Crm Deleted',
    CrmPageRequested = '[Crm List Page] Crm Page Requested',
    CrmPageLoaded = '[Crm API] Crm Page Loaded',
    CrmPageCancelled = '[Crm API] Crm Page Cancelled',
    CrmPageToggleLoading = '[Crm] Crm Page Toggle Loading',
    CrmActionToggleLoading = '[Crm] Crm Action Toggle Loading'
}

export class CrmOnServerCreated implements Action {
    readonly type = CrmActionTypes.CrmOnServerCreated;
    constructor(public payload: { crm: CrmModel, CrmId: number }) { }
}

export class CrmCreated implements Action {
    readonly type = CrmActionTypes.CrmCreated;
    constructor(public payload: { crm: CrmModel, CrmId: number }) { }
}

export class CrmUpdated implements Action {
    readonly type = CrmActionTypes.CrmUpdated;
    constructor(public payload: {
        partialCrm: Update<CrmModel>, // For State update
        crm: CrmModel, // For Server update (through service)
        CrmId: number
    }) { }
}

export class CrmStatusUpdated implements Action {
    readonly type = CrmActionTypes.CrmStatusUpdated;
    constructor(public payload: {
        crm: CrmModel[],
        status: number
    }) { }
}

export class OneCrmDeleted implements Action {
    readonly type = CrmActionTypes.OneCrmDeleted;
    constructor(public payload: { id: number, CrmId: number }) { }
}

export class ManyCrmDeleted implements Action {
    readonly type = CrmActionTypes.ManyCrmDeleted;
    constructor(public payload: { ids: number[] }) { }
}

export class CrmPageRequested implements Action {
    readonly type = CrmActionTypes.CrmPageRequested;
    constructor(public payload: { page: CrmQueryParamsModel }) { }
}

export class CrmPageLoaded implements Action {
    readonly type = CrmActionTypes.CrmPageLoaded;
    constructor(public payload: { crm: CrmModel[], totalCount: number, page: CrmQueryParamsModel }) { }
}

export class CrmPageCancelled implements Action {
    readonly type = CrmActionTypes.CrmPageCancelled;
}

export class CrmPageToggleLoading implements Action {
    readonly type = CrmActionTypes.CrmPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CrmActionToggleLoading implements Action {
    readonly type = CrmActionTypes.CrmActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type CrmActions = CrmOnServerCreated
    | CrmCreated
    | CrmUpdated
    | CrmStatusUpdated
    | OneCrmDeleted
    | ManyCrmDeleted
    | CrmPageRequested
    | CrmPageLoaded
    | CrmPageCancelled
    | CrmPageToggleLoading
    | CrmActionToggleLoading;
