// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { sitepagerowsService, usersActionToggleLoading, sitepagerowsModel } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitepagesrows-edit-dialog',
    templateUrl: './sitepagesrows-edit.dialog.component.html',
    styleUrls: ['./sitepagesrows-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class sitepagesrowsEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });

    // Public properties
    sitepagerows: sitepagerowsModel;
    sitepagerowsForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;
    filterContentSize = 'container';

    selectedusersId = 0;
    sitepagerowsResult: sitepagerowsModel[] = [];

    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<sitepagesrowsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitepagerowsService: sitepagerowsService,
        private layoutUtilsService: LayoutUtilsService,
        private cdRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.sitepagerows = this.data.users;
        if (this.sitepagerows.recordid === null || this.sitepagerows.recordid === undefined) { this.sitepagerows.recordid = 0; }
        this.createForm();
        this.isEditrow = true;
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    usersChangeAction() {
        this.cdRef.detectChanges();


    }

    onFileBgImageChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.sitepagerowsForm.get('Bgimage').setValue(file);
        }
    }

    createForm() {

        this.sitepagerowsForm = this.fb.group({

            RecordId: [this.sitepagerows.recordid > 0 ? this.sitepagerows.recordid : 0, Validators.nullValidator],
            Bgcolor: [this.sitepagerows.bgcolor, Validators.nullValidator],
            Bgcolor2: [this.sitepagerows.bgcolor2, Validators.nullValidator],
            Contentsize: [this.sitepagerows.contentsize, Validators.nullValidator],
            Bgimage: [this.sitepagerows.bgimage, Validators.nullValidator],
            Pageorder: [this.sitepagerows.pageorder, Validators.nullValidator],
        });
        this.filterContentSize = this.sitepagerows.contentsize;
        this.cdRef.detectChanges();
    }

    getTitle(): string {
        if (this.sitepagerows.recordid > 0) {
            return `Satır Düzenleme`;
        }

        return 'Yeni Satır';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.sitepagerowsForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    prepareusers(): sitepagerowsModel {
        const controls = this.sitepagerowsForm.controls;
        const users = new sitepagerowsModel();

        users.recordid = 0;
        users.contentsize = this.filterContentSize;
        users.bgcolor = controls.Bgcolor.value;
        users.bgcolor2 = controls.Bgcolor2.value;
        users.bgimage = controls.Bgimage.value;
        users.pageorder = controls.Pageorder.value;
        if (this.sitepagerows.recordid > 0) {
            users.recordid = this.sitepagerows.recordid;
        }
        else
            users.pageid = this.sitepagerows.pageid
        return users;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.sitepagerowsForm.controls;
        if (this.sitepagerowsForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const editsitepagerows = this.prepareusers();
        if (editsitepagerows.recordid > 0) {
            this.creatpagerows(editsitepagerows);
        } else {
            this.creatpagerows(editsitepagerows);
        }
    }

    updateusers(users: sitepagerowsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitepagerowsService.updatesitepagerows(users, Number(localStorage.getItem('UserId'))).subscribe(response => {

            if (response.status === 0) {
                this.store.dispatch(this.hideActionLoadingDistpatcher);
                this.dialogRef.close({ insertedid: response.value, status: response.status });
                // this.layoutUtilsService.alertElement('İşlem başarıyla gerçekleşti', 'İşlem başarılı!', '', 'Tamam');
            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }


        });

    }

    createusers(users: sitepagerowsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitepagerowsService.createsitepagerows(users, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
            this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        });
    }

    creatpagerows(users: sitepagerowsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        const formData = new FormData();

        formData.append('recordid', users.recordid.toString());
        formData.append('pageid', this.sitepagerows.pageid.toString());
        formData.append('contentsize', users.contentsize);
        formData.append('bgcolor', users.bgcolor);
        formData.append('bgcolor2', users.bgcolor2);
        formData.append('bgimage', users.bgimage);
        formData.append('pageorder', users.pageorder.toString());

        // this.sitepagerowsService.pagerowsinsert(formData).subscribe(response => {
        //     this.store.dispatch(this.hideActionLoadingDistpatcher);
        //     let StatusMessage = '';
        //     if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
        //         StatusMessage = response.errors[1].messageDescription;
        //     }
        //     this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        // });


        this.sitepagerowsService.pagerowsinsert(formData).subscribe(response => {
            if (response !== undefined) {
                if (response.status === 0) {
                    this.store.dispatch(this.hideActionLoadingDistpatcher);
                    this.dialogRef.close({ insertedid: response.value, status: response.status });
                    // this.layoutUtilsService.alertElement('İşlem başarıyla gerçekleşti', 'İşlem başarılı!', '', 'Tamam');
                }
                else {
                    this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
                }

            }
        });

    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }



}
