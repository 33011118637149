import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../_base/crud';
// State
import { AppState } from '../../reducers';
import { selectCrmInStore, selectCrmPageLoading, selectCrmShowInitWaitingMessage } from '../_selectors/crm.selectors';

export class CrmDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectCrmPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectCrmShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectCrmInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
