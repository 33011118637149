// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { usersService, usersModel, usersActionToggleLoading, settingsModel } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-usersettings-edit-dialog',
    templateUrl: './usersettings-edit.dialog.component.html',
    styleUrls: ['./usersettings-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class usersettingsEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });

    // Public properties
    users: usersModel;
    usersetting: settingsModel;
    usersForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

    selectedusersId = 0;
    usersResult: usersModel[] = [];
    filterHeaderType = "1";
    filterHeaderContentSize = 'container';
    filterHeaderMenuAlign = 'justify-content-center';
    filterHeaderMenuTextTransform = 'uppercase';
    HeaderLogoImageName = '';

    filterFooterType = '1';
    filterFooterContentSize = 'container';
    filterFooterMenuAlign = 'justify-content-center';
    filterFooterMenuTextTransform = 'uppercase';


    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<usersettingsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private usersService: usersService,
        private layoutUtilsService: LayoutUtilsService,
        private cdRef: ChangeDetectorRef,
        configPopover: NgbPopoverConfig) {
		configPopover.container = 'body';
    }


    ngOnInit() {

        this.usersForm = this.fb.group({
            domain: ['', Validators.nullValidator],
            favicon: ['', Validators.nullValidator],
            title: ['', Validators.nullValidator],
            phone: ['', Validators.nullValidator],
            mobilephone: ['', Validators.nullValidator],
            email: ['', Validators.nullValidator],
            address: ['', Validators.nullValidator],
            lat: ['', Validators.nullValidator],
            lon: ['', Validators.nullValidator],

            headerlogo: ['', Validators.nullValidator],
            headerlogowidth: ['', Validators.nullValidator],
            headerlogoheight: ['', Validators.nullValidator],
            headertype: ['', Validators.nullValidator],
            headerbgcolor: ['', Validators.nullValidator],
            headerbgimg: ['', Validators.nullValidator],
            headercontentsize: ['', Validators.nullValidator],
            headerheight: ['', Validators.nullValidator],
            headermenualign: ['', Validators.nullValidator],
            headermenutexttransform: ['', Validators.nullValidator],
            headermenufontsize: ['', Validators.nullValidator],
            headermenucolor: ['', Validators.nullValidator],
            headermenuhovercolor: ['', Validators.nullValidator],

            footerlogo: ['', Validators.nullValidator],
            footerlogowidth: ['', Validators.nullValidator],
            footerlogoheight: ['', Validators.nullValidator],

            footertype: ['', Validators.nullValidator],
            footerbgcolor: ['', Validators.nullValidator],
            footerbgimg: ['', Validators.nullValidator],
            footercontentsize: ['', Validators.nullValidator],
            footermenutexttransform: ['', Validators.nullValidator],
            footerheight: ['', Validators.nullValidator],
            footermenufontsize: ['', Validators.nullValidator],
            footermenucolor: ['', Validators.nullValidator],
            footermenuhovercolor: ['', Validators.nullValidator],



        });

        this.users = this.data.users;
        if (this.users.recordid === null || this.users.recordid === undefined) { this.users.recordid = 0; }
        this.isEditrow = true;
        this.loadsetting();
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    usersChangeAction() {
        this.cdRef.detectChanges();
    }
    onFileFaviconChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.usersForm.get('favicon').setValue(file);
        }
    }
    onFileHeaderLogoChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.usersForm.get('headerlogo').setValue(file);
        }
    }
    onFileHeaderBackImgChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.usersForm.get('headerbgimg').setValue(file);
        }
    }
    onFileFooterLogoChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.usersForm.get('footerlogo').setValue(file);
        }
    }
    onFileFooterBackImgChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.usersForm.get('footerbgimg').setValue(file);
        }
    }

    createForm() {
        const control = this.usersForm.controls;
        if (this.usersetting.recordid > 0) {
            control['domain'].setValue(this.usersetting.domain);
            control['favicon'].setValue(this.usersetting.favicon);
            control['title'].setValue(this.usersetting.title);
            control['phone'].setValue(this.usersetting.phone);
            control['mobilephone'].setValue(this.usersetting.mobilephone);
            control['email'].setValue(this.usersetting.email);
            control['address'].setValue(this.usersetting.address);
            control['lat'].setValue(this.usersetting.lat);
            control['lon'].setValue(this.usersetting.lon);

            control['headerlogo'].setValue(this.usersetting.headerlogo);
            control['headerlogowidth'].setValue(this.usersetting.headerlogowidth);
            control['headerlogoheight'].setValue(this.usersetting.headerlogoheight);
            control['headertype'].setValue(this.filterHeaderType);
            control['headerbgimg'].setValue(this.usersetting.headerbgimg);
            control['headercontentsize'].setValue(this.usersetting.headercontentsize);
            control['headerheight'].setValue(this.usersetting.headerheight);
            control['headermenualign'].setValue(this.usersetting.headermenualign);
            control['headermenutexttransform'].setValue(this.usersetting.headermenutexttransform);
            control['headermenufontsize'].setValue(this.usersetting.headermenufontsize);
            control['headerbgcolor'].setValue(this.usersetting.headerbgcolor);
            control['headermenucolor'].setValue(this.usersetting.headermenucolor);
            control['headermenuhovercolor'].setValue(this.usersetting.headermenuhovercolor);

            control['footertype'].setValue(this.usersetting.footertype);
            control['footerbgimg'].setValue(this.usersetting.footerbgimg);
            control['footercontentsize'].setValue(this.usersetting.footercontentsize);
            control['footermenutexttransform'].setValue(this.usersetting.footermenutexttransform);
            control['footerheight'].setValue(this.usersetting.footerheight);
            control['footerlogo'].setValue(this.usersetting.footerlogo);
            control['footerlogowidth'].setValue(this.usersetting.footerlogowidth);
            control['footerlogoheight'].setValue(this.usersetting.footerlogoheight);
            control['footermenufontsize'].setValue(this.usersetting.footermenufontsize);
            control['footerbgcolor'].setValue(this.usersetting.footerbgcolor);
            control['footermenucolor'].setValue(this.usersetting.footermenucolor);
            control['footermenuhovercolor'].setValue(this.usersetting.footermenuhovercolor);



            //     this.usersForm = this.fb.group({
            //         Name: [this.users.name, Validators.nullValidator],
            //          Email: [this.users.email, Validators.nullValidator],
            //          SocketAddress: [this.users.socketaddress, Validators.nullValidator],


            //     });
            // } else {
            //     this.usersForm = this.fb.group({
            //         Name: [this.users.name, Validators.nullValidator],
            //         Email: [this.users.email, Validators.nullValidator],
            //          SocketAddress: [this.users.socketaddress, Validators.nullValidator],

            //     });
        }
        this.usersChangeAction();
    }

    getTitle(): string {
        return `Düzenlenen Site  :   ${this.usersetting !== undefined ? this.usersetting.title : ''}  `;
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.usersForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    prepareusers(): settingsModel {
        const controls = this.usersForm.controls;
        const users = new settingsModel();
        if (this.users.recordid > 0)
            users.recordid = this.users.recordid;
        else
            users.recordid = 0;

        users.domain = controls.domain.value;
        users.favicon = controls.favicon.value;
        users.title = controls.title.value;
        users.phone = controls.phone.value;
        users.mobilephone = controls.mobilephone.value;
        users.email = controls.email.value;
        users.address = controls.address.value;
        users.lat = controls.lat.value;
        users.lon = controls.lon.value;
        users.headerlogo = controls.headerlogo.value;
        users.headerlogoheight = controls.headerlogoheight.value;
        users.headerlogowidth = controls.headerlogowidth.value;
        users.headertype = controls.headertype.value;
        users.headerbgimg = controls.headerbgimg.value;
        users.headercontentsize = controls.headercontentsize.value;
        users.headerheight = controls.headerheight.value;
        users.headermenualign = controls.headermenualign.value;
        users.headermenutexttransform = controls.headermenutexttransform.value;
        users.headermenufontsize = controls.headermenufontsize.value;
        users.headerbgcolor = controls.headerbgcolor.value;
        users.headermenucolor = controls.headermenucolor.value;
        users.headermenuhovercolor = controls.headermenuhovercolor.value;

        users.footerlogo = controls.footerlogo.value;
        users.footerlogoheight = controls.footerlogoheight.value;
        users.footerlogowidth = controls.footerlogowidth.value;
        users.footertype = controls.footertype.value;
        users.footerbgimg = controls.footerbgimg.value;
        users.footercontentsize = controls.footercontentsize.value;
        users.footerheight = controls.footerheight.value;
        //users.footermenualign = controls.footermenualign.value;
        users.footermenutexttransform = controls.footermenutexttransform.value;
        users.footermenufontsize = controls.footermenufontsize.value;
        users.footerbgcolor = controls.footerbgcolor.value;
        users.footermenucolor = controls.footermenucolor.value;
        users.footermenuhovercolor = controls.footermenuhovercolor.value;
        return users;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.usersForm.controls;
        if (this.usersForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const editedusers = this.prepareusers();
        if (editedusers.recordid > 0) {
            this.creatsettings(editedusers);
        } else {
            this.creatsettings(editedusers);
        }
    }

    creatsettings(settings: settingsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        const formData = new FormData();
        // formData.append('file', settings.headerlogo);
        //formData.append('Image', this.HeaderLogoImageName);
        formData.append('recordid', settings.recordid.toString());
        formData.append('userid', this.usersetting.userid.toString());
        formData.append('domain', settings.domain);
        formData.append('favicon', settings.favicon);
        formData.append('title', settings.title);
        formData.append('phone', settings.phone);
        formData.append('mobilephone', settings.mobilephone);
        formData.append('email', settings.email);
        formData.append('address', settings.address);
        formData.append('lat', settings.lat);
        formData.append('lon', settings.lon);

        formData.append('headerlogo', settings.headerlogo);
        formData.append('headerlogowidth', settings.headerlogowidth);
        formData.append('headerlogoheight', settings.headerlogoheight);
        formData.append('headertype', this.filterHeaderType.toString());
        formData.append('headerbgimg', settings.headerbgimg);
        formData.append('headercontentsize', settings.headercontentsize);
        formData.append('headerheight', settings.headerheight);
        formData.append('headermenualign', this.filterFooterMenuAlign.toString());
        formData.append('headermenutexttransform', this.filterHeaderMenuTextTransform.toString());
        formData.append('headermenufontsize', settings.headermenufontsize);
        formData.append('headerbgcolor', settings.headerbgcolor);
        formData.append('headermenucolor', settings.headermenucolor);
        formData.append('headermenuhovercolor', settings.headermenuhovercolor);

        formData.append('footerlogo', settings.footerlogo);
        formData.append('footerlogowidth', settings.footerlogowidth);
        formData.append('footerlogoheight', settings.footerlogoheight);
        formData.append('footertype', settings.footertype.toString());
        formData.append('footerbgimg', settings.footerbgimg);
        formData.append('footercontentsize', settings.footercontentsize);
        formData.append('footerheight', settings.footerheight);
        formData.append('footermenualign', this.filterFooterMenuAlign.toString());
        formData.append('footermenutexttransform', this.filterFooterMenuTextTransform);
        formData.append('footermenufontsize', settings.footermenufontsize);
        formData.append('footerbgcolor', settings.footerbgcolor);
        formData.append('footermenucolor', settings.footermenucolor);
        formData.append('footermenuhovercolor', settings.footermenuhovercolor);

        this.usersService.createusersettings(formData).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
            this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    loadsetting() {
        this.usersService.getusersettingsByuserid(this.data.users.recordid)
            .subscribe(response => {
                const data = JSON.parse(response.value.json);
                this.usersetting = data[0];

                this.HeaderLogoImageName = this.usersetting.headerlogo;
                this.filterHeaderType = this.usersetting.headertype.toString();
                this.filterFooterType = this.usersetting.footertype.toString();
                this.filterHeaderMenuTextTransform = this.usersetting.headermenutexttransform.toString();
                this.filterHeaderMenuAlign = this.usersetting.headermenualign.toString();
                this.filterFooterMenuTextTransform = this.usersetting.footermenutexttransform.toString();
                this.filterFooterMenuAlign = this.usersetting.headermenualign.toString();

                this.createForm();
                this.cdRef.detectChanges();


            });

    }





}
