// USA
export const locale = {
    lang: 'tr',
    data: {
        TRANSLATOR: {
            SELECT: 'Dil Seç',
        },
        MENU: {
            NEW: 'yeni',
            ACTIONS: 'Actions',
            CREATE_POST: 'Create New Post',
            PAGES: 'Pages',
            FEATURES: 'Features',
            APPS: 'Apps',
            DASHBOARD: 'Ana Sayfa',
        },
        AUTH: {
            GENERAL: {
                OR: 'yada',
                SUBMIT_BUTTON: 'Gönder',
                NO_ACCOUNT: 'Üye Kaydınız yok mu?',
                SIGNUP_BUTTON: 'Kayıt Ol',
                FORGOT_BUTTON: 'Şifremi Unuttum',
                BACK_BUTTON: 'Geri',
                PRIVACY: 'Gizlilik',
                LEGAL: 'Yasal',
                CONTACT: 'İletişim',
            },
            LOGIN: {
                TITLE: 'Kullanıcı Girişi',
                BUTTON: 'Oturum Aç',
            },
            FORGOT: {
                TITLE: 'Şifremi Unuttum?',
                DESC: 'Şifrenizi sıfırlamak için e-posta adresinizi girin',
                SUCCESS: 'Hesabınız başarıyla sıfırlandı.'
            },
            REGISTER: {
                TITLE: 'Müşteri Kayıt Ol',
                DESC: 'Hesabınızı oluşturmak için bilgilerinizi girin',
                SUCCESS: 'Hesabınız başarıyla kaydedildi.'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Adınız',
                PASSWORD: 'Şifre',
                CONFIRM_PASSWORD: 'Şifre Tekrar',
                USERNAME: 'Kullanıcı Adı'
            },
            VALIDATION: {
                INVALID: '{{name}} geçerli değil.',
                REQUIRED: '{{name}} zorunlu alan.',
                MIN_LENGTH: '{{name}} minimum {{min}} karakter olmalıdır.',
                AGREEMENT_REQUIRED: 'Kabul şartlar ve koşullar kabul etmelisiniz.',
                NOT_FOUND: 'İstenilen {{name}} sistemde mevcut değildir.',
                INVALID_LOGIN: 'Giriş başarısız. Lütfen bilgilerinizi tekrar kontrol ediniz.',
                REQUIRED_FIELD: 'Zorunlu alan',
                MIN_LENGTH_FIELD: 'Minimum uzunluk:',
                MAX_LENGTH_FIELD: 'Maksimum uzunluk:',
                INVALID_FIELD: 'Geçerli değil',
            }
        },
        SYSTEM: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Seçilen kayıt sayısı: ',
                ALL: 'Tümü',
                SUSPENDED: 'Askıda',
                ACTIVE: 'Aktif',
                FILTER: 'Filtrele',
                BY_STATUS: 'Statüse göre',
                SEARCH: 'Arama',
                IN_ALL_FIELDS: 'tüm içerikte'
            },
            SYSTEM: 'Sistem',
            USERS: {
                USERS: 'Kullanıcılar',
                USER_LIST: 'Kullanıcı Listesi',
                NEW_USER: 'Yeni Kullanıcı',
                DELETE_USER_SIMPLE: {
                    TITLE: 'Kullanıcı Sil',
                    DESCRIPTION: 'Bu kullanıcı kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'Kullanıcı siliniyor...',
                    MESSAGE: 'Kullanıcı silindi.'
                },
                DELETE_USER_MULTY: {
                    TITLE: 'Kullanıcı Sil',
                    DESCRIPTION: 'Seçtiğiniz kullanıcılar kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'Kullanıcılar Siliniyor...',
                    MESSAGE: 'Seçilen kullanıcılar silinmiştir.'
                },
                UPDATE_STATUS: {
                    TITLE: 'Seçilen kullanıcıların durumunu güncelle',
                    MESSAGE: 'Seçilen kullanıcıların durumu güncellenmiştir.'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Kullanıcı güncellenmiştir.',
                    ADD_MESSAGE: 'Kullanıcı eklenmiştir.'
                }
            }
        },
        LAWOFFICE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Seçilen kayıt sayısı: ',
                ALL: 'Tümü',
                SUSPENDED: 'Askıda',
                ACTIVE: 'Aktif',
                FILTER: 'Filtrele',
                BY_STATUS: 'Statüse göre',
                SEARCH: 'Arama',
                IN_ALL_FIELDS: 'tüm içerikte'
            },
            LAWOFFICE: 'Hukuk Büroları',
            COMPANIES: {
                COMPANY: 'İşletmeler',
                COMPANY_LIST: 'İşletme Listesi',
                LAWOFFICES_LIST: 'Hukuk Büroları Listesi',
                COMPANY_WAIT_LIST: 'Onay Bekleyen İşletme Listesi',
                INVOICEPROCESSING: 'Fatura İşlemleri',
                LICANSECREATELIST: 'Lisans Başvuru Formu',
                NEW_COMPANY: 'Yeni İşletme',
                DELETE_COMPANY_SIMPLE: {
                    TITLE: 'İşletme Sil',
                    DESCRIPTION: 'Bu firma kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'İşletme siliniyor...',
                    MESSAGE: 'İşletme silindi.'
                },
                DELETE_COMPANY_MULTY: {
                    TITLE: 'İşletme Sil',
                    DESCRIPTION: 'Seçtiğiniz firmalar kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'İşletmeler Siliniyor...',
                    MESSAGE: 'Seçilen İşletmeler silinmiştir.'
                },
                UPDATE_STATUS: {
                    TITLE: 'Seçilen firmaların durumunu güncelle',
                    MESSAGE: 'Seçilen firmaların durumu güncellenmiştir.'
                },
                UPDATE_INVOICESTATUS: {
                    TITLE: 'Seçilen firmaların fatura durumunu güncelle',
                    MESSAGE: 'Seçilen firmaların fatura durumu güncellenmiştir.'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'İşletme güncellenmiştir.',
                    ADD_MESSAGE: 'İşletme eklenmiştir.'
                }
            },
            LICENSE: {
                EDIT: {
                    UPDATE_MESSAGE: 'Başvuru Formu güncellenmiştir.',
                    ADD_MESSAGE: 'Başvuru Formu eklenmiştir.'
                }
            }
        },
        REGIONS: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'Seçilen kayıt sayısı: ',
                ALL: 'Tümü',
                SUSPENDED: 'Askıda',
                ACTIVE: 'Aktif',
                FILTER: 'Filtrele',
                BY_STATUS: 'Statüse göre',
                SEARCH: 'Arama',
                IN_ALL_FIELDS: 'tüm içerikte'
            },
            REGIONS: {
                REGION: 'Bölgeler',
                REGION_LIST: 'Bölge Listesi',
                NEW_REGION: 'Yeni Bölge',
                DELETE_REGION_SIMPLE: {
                    TITLE: 'Bölge Sil',
                    DESCRIPTION: 'Bu bölge kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'Bölge siliniyor...',
                    MESSAGE: 'Bölge silindi.'
                },
                DELETE_REGION_MULTY: {
                    TITLE: 'Bölge Sil',
                    DESCRIPTION: 'Seçtiğiniz bölgeler kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'Bölgeler Siliniyor...',
                    MESSAGE: 'Seçilen Bölgeler silinmiştir.'
                },
                UPDATE_STATUS: {
                    TITLE: 'Seçilen bölgelerın durumunu güncelle',
                    MESSAGE: 'Seçilen bölgelerın durumu güncellenmiştir.'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Bölge güncellenmiştir.',
                    ADD_MESSAGE: 'Bölge eklenmiştir.'
                }
            },
            CITIES: {
                CITIES: 'İller',
                CITY_LIST: 'İl Listesi',
                NEW_CITY: 'Yeni İl',
                DELETE_CITY_SIMPLE: {
                    TITLE: 'İl Sil',
                    DESCRIPTION: 'Bu il kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'il siliniyor...',
                    MESSAGE: 'İl silindi.'
                },
                DELETE_CITY_MULTY: {
                    TITLE: 'İl Sil',
                    DESCRIPTION: 'Seçtiğiniz iller kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'iller Siliniyor...',
                    MESSAGE: 'Seçilen iller silinmiştir.'
                },
                UPDATE_STATUS: {
                    TITLE: 'Seçilen iller durumunu güncelle',
                    MESSAGE: 'Seçilen iller durumu güncellenmiştir.'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'İl güncellenmiştir.',
                    ADD_MESSAGE: 'İl eklenmiştir.'
                }
            },
            TOWNS: {
                TOWNS: 'İlçeler',
                TOWN_LIST: 'İlçe Listesi',
                NEW_TOWN: 'Yeni İlçe',
                DELETE_TOWN_SIMPLE: {
                    TITLE: 'İlçe Sil',
                    DESCRIPTION: 'Bu ilçe kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'İlçe siliniyor...',
                    MESSAGE: 'İlçe silindi.'
                },
                DELETE_TOWN_MULTY: {
                    TITLE: 'İlçe Sil',
                    DESCRIPTION: 'Seçtiğiniz ilçeler kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'İlçeler Siliniyor...',
                    MESSAGE: 'Seçilen İlçeler silinmiştir.'
                },
                UPDATE_STATUS: {
                    TITLE: 'Seçilen ilçelerin durumunu güncelle',
                    MESSAGE: 'Seçilen ilçelerin durumu güncellenmiştir.'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'İlçe güncellenmiştir.',
                    ADD_MESSAGE: 'İlçe eklenmiştir.'
                }
            },
            NEIGHBORHOODS: {
                NEIGHBORHOODS: 'Mahalleler',
                NEIGHBORHOOD_LIST: 'Mahalle Listesi',
                NEW_NEIGHBORHOOD: 'Yeni Mahalle',
                DELETE_NEIGHBORHOOD_SIMPLE: {
                    TITLE: 'Mahalle Sil',
                    DESCRIPTION: 'Bu mahalle kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'Mahalle siliniyor...',
                    MESSAGE: 'Mahalle silindi.'
                },
                DELETE_NEIGHBORHOOD_MULTY: {
                    TITLE: 'Mahalle Sil',
                    DESCRIPTION: 'Seçtiğiniz mahalleler kalıcı olarak sildiğinizden emin misiniz?',
                    WAIT_DESCRIPTION: 'Mahalleler Siliniyor...',
                    MESSAGE: 'Seçilen mahalleler silinmiştir.'
                },
                UPDATE_STATUS: {
                    TITLE: 'Seçilen mahallelerin durumunu güncelle',
                    MESSAGE: 'Seçilen mahallelerin durumu güncellenmiştir.'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Mahalle güncellenmiştir.',
                    ADD_MESSAGE: 'Mahalle eklenmiştir.'
                }
            },
        }
    }
};
