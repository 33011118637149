import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class sitepagerowsModel extends BaseModel {
    id: number;
    recordid: number;
    status: boolean;
    isdeleted: boolean;
    pageid: number;
    contentsize: string;
    bgcolor: string;
    bgcolor2: string;
    bgimage: string;
    pageorder: number;
   
    clear() {
        this.id = 0;
        this.recordid = 0;
        this.pageid = 0;
        this.status = true;
        this.isdeleted = false;
        this.contentsize = '';
        this.bgcolor = '';
        this.bgcolor2 = '';
        this.bgimage = '';
        this.pageorder = 99;
      }
}
