// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
// Models
import { sitepagerowsModel } from '../_models/sitepagerows/sitepagerows.model';

import { BaseClass } from '../../../BaseClass';
import { map } from 'lodash';
import { ResponseBaseObject } from '../../auth/_models/ResponseBaseObject.model';
import { catchError } from 'rxjs/operators';
import { sitepagerowsQueryParamsModel } from '../_models/sitepagerows/sitepagerows-query-params.model';
import { environment } from '../../../../environments/environment';





@Injectable()
export class sitepagerowsService {
    API_USERS_URL = '';
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, public baseClass: BaseClass) {
        this.API_USERS_URL = this.baseClass.apiUrl + 'pagerows';
    }

    // CREATE =>  POST: add a new sitepagerows to the server
    createsitepagerows(sitepagerows: sitepagerowsModel, sitepagerowsId: number): Observable<any> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updatesitepagerows';
        const data: String = JSON.stringify(sitepagerows);

        return this.http.put(url, data, options);


        // // Note: Add headers if needed (tokens/bearer)
        // const url = this.API_USERS_URL + '/createsitepagerows';
        // const httpHeaders = this.httpUtils.getHTTPHeaders();
        // return this.http.post<ResponseBaseObject>(url, sitepagerows, { headers: httpHeaders });
    }

    // READ
    getAllsitepagerows(): Observable<any> {
        return this.http.get<any>(this.API_USERS_URL+"/getallpages");
    }

    getsitepagerowsById(sitepagerowsId: number): Observable<sitepagerowsModel> {
        return this.http.get<sitepagerowsModel>(this.API_USERS_URL + `/${sitepagerowsId}`);
    }
    getsitepagerowsByPageId(id: number): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + `/getbypageid/${id}`);
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    findsitepagerows(queryParams: sitepagerowsQueryParamsModel): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headersParams = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });

        const httpParams = new HttpParams()
            .set('pageid', queryParams.pageid.toString())
            .set('status', queryParams.Status.toString())
            .set('searchtext', queryParams.searchtext)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
            

        const url = this.API_USERS_URL + '/ForGrid';
        const options = { headers: headersParams, params: httpParams };
        return this.http.get<ResponseBaseObject>(url, options);
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    // UPDATE => PUT: update the sitepagerows on the server
    updatesitepagerows(sitepagerows: sitepagerowsModel, userId: number): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/update';
        const data: String = JSON.stringify(sitepagerows);

        return this.http.put(url, data, options);
    }
  
    // UPDATE Status
    updateStatusForsitepagerows(sitepagerows: sitepagerowsModel[], status: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {
            sitepagerowsForUpdate: sitepagerows,
            newStatus: status
        };
        const url = this.API_USERS_URL + '/update';
        return this.http.put(url, body, { headers: httpHeaders });
    }

    getAllRoles(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + '/Roles');
    }

    
    pagerowsinsert(formData: FormData): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const url = this.API_USERS_URL + '/pagerowsinsert';

        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set('Authorization', 'Bearer ' + userToken);

        return this.http.post<ResponseBaseObject>(url, formData, { headers: httpHeaders });

       
    }
}
