import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class usersModel extends BaseModel {
 
    id: number;
    recordid: number;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    countryid: number;
    cityid: number;
    stateid: number;
    usertype: number;
    companyid: number;
    tckimlik: string;
    vergino: string;
    address: string;
    phone: string;
    customertitle:string;
    customertitleid:number;
    code:string;
    roleid: number;

    clear(): void {
        this.id = 0;
        this.recordid = 0;
        this.password = '';
        this.email = '';
        this.firstname = '';
        this.lastname = '';
        this.countryid = 0;
        this.usertype = 0;
        this.cityid = 0;
        this.stateid = 0;
        this.companyid=0;
        this.tckimlik = '';
        this.vergino = '';
        this.address = '';
        
        this.phone = '';
        this.roleid = 0;
        this.customertitle='';
        this.customertitleid=0;
        this.code='';
        
        
    }
}
