// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { usersService, usersModel, usersActionToggleLoading } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
// import { NgxMaskModule, IConfig } from 'ngx-mask'


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-users-edit-dialog',
    templateUrl: './mypage-edit.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class mypageEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });

    // Public properties
    users: usersModel;
    usersForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

 

    // Private properties
    private componentSubscriptions: Subscription;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private usersService: usersService,
        private cdRef: ChangeDetectorRef,
        private fb: FormBuilder,
    ) { }

  

    ngOnInit() {
        this.createForminit();
    this.loadUser();

      
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    usersChangeAction() {
        this.cdRef.detectChanges();
    }
    createForminit() {
        this.usersForm = this.fb.group({
            code: ['', Validators.nullValidator],
            email: ['', Validators.nullValidator],
            firstname: ['', Validators.nullValidator],
            lastname: ['', Validators.nullValidator],
            phone: ['', Validators.nullValidator],
            tckimlik: ['', Validators.nullValidator],
            vergino: ['', Validators.nullValidator],
            address: ['', Validators.nullValidator],
            password: ['', Validators.nullValidator],
            password2: ['', Validators.nullValidator],


        });
    
    this.usersChangeAction();
    this.cdRef.detectChanges();
}

    createForm() {
            this.usersForm = this.fb.group({
                email: [this.users.email, Validators.nullValidator],
                code: [this.users.code, Validators.nullValidator],
                firstname: [this.users.firstname, Validators.nullValidator],
                lastname: [this.users.lastname, Validators.nullValidator],
                phone: [this.users.phone, Validators.nullValidator],
                tckimlik: [this.users.tckimlik, Validators.nullValidator],
                vergino: [this.users.vergino, Validators.nullValidator],
                address: [this.users.address, Validators.nullValidator],
                password: ['', Validators.nullValidator],
                password2: ['', Validators.nullValidator],


            });
        
        this.usersChangeAction();
        this.cdRef.detectChanges();
    }

   

    isControlInvalid(controlName: string): boolean {
        const control = this.usersForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    prepareusers(): usersModel {
        const controls = this.usersForm.controls;
        const users = new usersModel();
        if (this.users.recordid > 0) {
            users.recordid = this.users.recordid;
            users.email = this.users.email;
            users.code = this.users.code;
            users.firstname = controls.firstname.value;
            users.lastname = controls.lastname.value;
            users.phone = controls.phone.value;
            users.tckimlik = controls.tckimlik.value;
            users.vergino = controls.vergino.value;
            users.address = controls.address.value;
            if (controls.password.value) {
                if (controls.password.value !== controls.password2.value) {
                    this.layoutUtilsService.alertElement('Şifre Değişikliği', 'Şifreler farklı ! Şifre değiştirmek istemiyorsanız lütfen şifre alalnlarını boş bırakınız', '', 'Tamam');
                    return;
                }
                else
                    users.password = controls.password.value;
            }

        }

        return users;
    }

    onSubmit() {
        this.hasFormErrors = false;
        // const controls = this.usersForm.controls;
        // if (this.usersForm.invalid) {
        //     Object.keys(controls).forEach(controlName =>
        //         controls[controlName].markAsTouched()
        //     );

        //     this.hasFormErrors = true;
        //     return;
        // }

        this.hasFormErrors = false;
        
        const controls = this.usersForm.controls;
        if(!controls.tckimlik.value && !controls.vergino.value ){
            this.hasFormErrors = true;
            this.layoutUtilsService.alertElement('Uyarı', 'Vergi Numarası veya TC Kimlik alanlarından biri dolu olmalıdır', '', 'Tamam');
            return;
}

        if(!controls.firstname.value || !controls.lastname.value|| !controls.email.value|| !controls.phone.value ){
            this.hasFormErrors = true;
            this.layoutUtilsService.alertElement('Uyarı', 'Tüm alanları doldurunuz ', '', 'Tamam');
            return;
        }

        const editedusers = this.prepareusers();
        if (editedusers.recordid > 0) {
            this.updateusers(editedusers);
        } else {
            this.updateusers(editedusers);
        }
    }

    updateusers(users: usersModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.usersService.updateusers(users, Number(localStorage.getItem('UserId'))).subscribe(response => {

            if (response.status === 0) {
                this.store.dispatch(this.hideActionLoadingDistpatcher);
                 this.layoutUtilsService.alertElement('İşlem başarıyla gerçekleşti', 'Girmiş olduğunuz bilgiler neticesinde kullanıcı bilgileriniz güncellendi. Kullanıcı bilgilerinizi doğruluğundan emin olunuz.', '', 'Tamam');
            }else if (response.status === 11) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu email adresi ( '+users.email+' )sistemde kayıtlıdır.', '', 'Tamam');
                this.usersForm.controls['email'].setValue('');
            }else if (response.status === 12) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu telefon bilgisi( '+users.phone+' ) sistemde kayıtlıdır.', '', 'Tamam');
                this.usersForm.controls['phone'].setValue('');
            }else if (response.status === 13) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu TC Kimlik numarası ( '+users.tckimlik+' )sistemde kayıtlıdır.', '', 'Tamam');
                this.usersForm.controls['tckimlik'].setValue('');
            }else if (response.status === 14) {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'Bu Vergi numarası( '+users.vergino+' ) sistemde kayıtlıdır.', '', 'Tamam');
                this.usersForm.controls['vergino'].setValue('');
            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }


        });

    }

    createusers(users: usersModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.usersService.createusers(users, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
       
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }
    loadUser() {
        this.usersService.getmyinf().subscribe(response => {
            // this.processmusterilistService.getprocessmusterilistByUserId(this.filteruserId).subscribe(res => {

            const data = 
            JSON.parse(response.value.json);
            this.users = data[0];
            this.createForm();
            this.isEditrow = true;

           
            this.cdRef.detectChanges();
        });

    }


}
