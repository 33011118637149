// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryResultsModel } from '../../_base/crud';
// Models
import { usersModel } from '../_models/users/users.model';

import { BaseClass } from '../../../BaseClass';
import { map } from 'lodash';
import { ResponseBaseObject } from '../../auth/_models/ResponseBaseObject.model';
import { catchError } from 'rxjs/operators';
import { usersQueryParamsModel } from '../_models/users/users-query-params.model';
import { settingsModel } from '../_models/users/settings.model';
import { environment } from '../../../../environments/environment';





@Injectable()
export class usersService {
    API_USERS_URL = '';
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, public baseClass: BaseClass) {
        this.API_USERS_URL = this.baseClass.apiUrl + 'users';
    }

    // CREATE =>  POST: add a new users to the server
    createusers(users: usersModel, usersId: number): Observable<any> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updateusers';
        const data: String = JSON.stringify(users);

        return this.http.put(url, data, options);


        // // Note: Add headers if needed (tokens/bearer)
        // const url = this.API_USERS_URL + '/createusers';
        // const httpHeaders = this.httpUtils.getHTTPHeaders();
        // return this.http.post<ResponseBaseObject>(url, users, { headers: httpHeaders });
    }

    // READ
    getAllusers(): Observable<usersModel[]> {
        return this.http.get<usersModel[]>(this.API_USERS_URL);
    }

    // Kullanıcı eğer 1 id'li  kullanıcı ise tüm kullanıcıları normal kullanıcı ise sadece kendini getirmektedir.
    getAllusersByUserid(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL+ `/getallbyuserid`);
    }
    getAllusersByCompanyid(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL+ `/getallbycompanyid`);
    }


    
getmyinf(): Observable<any> {
        return this.http.get<usersModel>(this.API_USERS_URL +`/getmyinf`);
    }

    getusersById(usersId: number): Observable<usersModel> {
        return this.http.get<usersModel>(this.API_USERS_URL + `/${usersId}`);
    }

    getusersByTckimlik(identity: string): Observable<any>  {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/getbytckimlik';
        const data: String = '{\"tckimlik\" : \"' + identity + '\" }';
        return this.http.post(url, data, options);
    }

    getuserByCode(code: string): Observable<any>  {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/getbycode';
        const data: String = '{\"code\" : \"' + code + '\" }';
        return this.http.post(url, data, options);
    }

    getusersByVergino(identity: string): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + `/getbyvergino/${identity}`);
    }
   

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    findusers(queryParams: usersQueryParamsModel): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headersParams = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });

        const httpParams = new HttpParams()
            .set('status', queryParams.Status.toString())
            .set('searchtext', queryParams.searchtext)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())

        const url = this.API_USERS_URL + '/ForGrid';
        const options = { headers: headersParams, params: httpParams };
        return this.http.get<ResponseBaseObject>(url, options);
    }
    findusersayarevi(queryParams: usersQueryParamsModel): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headersParams = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });

        const httpParams = new HttpParams()
            .set('status', queryParams.Status.toString())
            .set('searchtext', queryParams.searchtext)
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('pageNumber', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())

        const url = this.API_USERS_URL + '/ForGridAyarEvi';
        const options = { headers: headersParams, params: httpParams };
        return this.http.get<ResponseBaseObject>(url, options);
    }

    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }

    // UPDATE => PUT: update the users on the server
    updateusers(users: usersModel, userId: number): Observable<any> {
      
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updateusers';
        const data: String = JSON.stringify(users);

        return this.http.post(url, data, options);
    }
    
    updateayareviusers(users: usersModel, userId: number): Observable<any> {
      
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updateayareviusers';
        const data: String = JSON.stringify(users);

        return this.http.post(url, data, options);
    }
    updateayarevinewcustomer(users: usersModel, userId: number): Observable<any> {
      
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updateayarevinewcustomer';
        const data: String = JSON.stringify(users);

        return this.http.post(url, data, options);
    }
    


    resetpassword(users: usersModel): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        
        const url = this.API_USERS_URL + '/resetpassword/'+(users.recordid+1000000).toString();
        const data: String = JSON.stringify(users);

        return this.http.put(url, data, options);
    }
    updatedeviceconfigurationwifi(dcRecordId: number, wifiname: string, wifipass: string): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/updatedeviceconfiguration';
        const data: String = '{\"RecordId\":' + dcRecordId.toString() + ',\"AYWA\" : \"' + wifiname + '\",\"AYWS\" : \"' + wifipass + '\" }';

        return this.http.put(url, data, options);
    }
    updateversion(AAAA : string , fhaa : string ): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json',  'Authorization': 'Bearer ' + userToken});
        const options = { headers };
        const url = this.API_USERS_URL + '/updateversions';
        const data: String = '{\"AAAA\" : \"'+AAAA+'\",\"FHAA\" : \"'+fhaa+'\" }';

        return this.http.put(url, data, options);
    }
    

    // UPDATE Status
    updateStatusForUsers(RecId:number, status: number): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const body = {
            recordid: RecId,
            status: status
        };
        const url = this.API_USERS_URL + '/updateusersstatus';
        return this.http.put(url, body, { headers: httpHeaders });
    }

    getAllRoles(): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL + '/Roles');
    }

    getusersettingsByuserid(userid: number): Observable<ResponseBaseObject> {
        return this.http.get<ResponseBaseObject>(this.API_USERS_URL +"/getsettingsbyid/"+ userid.toString());
    }

    createusersettings(formData: FormData): Observable<ResponseBaseObject> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const url = this.API_USERS_URL + '/settingsinsert';

        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set('Authorization', 'Bearer ' + userToken);

        return this.http.post<ResponseBaseObject>(url, formData, { headers: httpHeaders });

        // // Note: Add headers if needed (tokens/bearer)
        // const url = this.API_USERS_URL + '/createsliders';
        // const httpHeaders = this.httpUtils.getHTTPHeaders();
        // return this.http.post<ResponseBaseObject>(url, sliders, { headers: httpHeaders });
    }

    ContactDetailsUpdate(settingsModel: settingsModel): Observable<any> {

        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.API_USERS_URL + '/ContactDetailsUpdate';
        const data: String = JSON.stringify(settingsModel);

        return this.http.post(url, data, options);
    }
    updatecustomertitle(process: usersModel): Observable<any> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userToken });
        const options = { headers };
        const url = this.baseClass.apiUrl  + 'companycustomerinformation/updatecustomertitle';
        const data: String = JSON.stringify(process);

        return this.http.put(url, data, options);
    }

    

}
