import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class BaseClass {

    constructor(private router: Router) {
    }
  
   //public apiUrl = 'http://localhost:5000/';
    public apiUrl = 'http://acsapi.aifanet.com/';

   

    currentUser(): any {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    UserId(): number {
        return this.isActivate() === true ? this.currentUser().UserId : 0;
    }

    getRoleId(): number {
        return this.isActivate() === true ? Number(localStorage.getItem('RoleId')) : 0;
    }

    isActivate(): boolean {
        if (localStorage.getItem('currentUser')) {
            return true;
        }
        return false;
    }

    logOut() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentUser');
        return true;
    }

}
