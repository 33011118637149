import { now } from 'lodash';
import { BaseModel } from '../../../_base/crud';

export class sitewidgetsModel extends BaseModel {
    id: number;
    recordid: number;
    status: number;
    isdeleted: boolean;
    pagerowid: number;
    widgetname: string;
    size: string;
    widgetorder: number;
   
    clear() {
        this.id = 0;
        this.recordid = 0;
        this.status = 1;
        this.isdeleted = false;
        this.pagerowid = 0;
        this.widgetname = '';
        this.size = '';
        this.widgetorder = 99;
      }
}
