import { Injectable } from '@angular/core';


@Injectable()
export class processayarevireportsQueryParamsModel {

    searchtext: string;
    sortOrder: string; // asc || desc
    sortField: string;
    pageNumber: number;
    pageSize: number;
    Status: number;

    // constructor overrides
    // tslint:disable-next-line: variable-name
    constructor(_searchtext: string = '', _sortOrder: string = 'asc', _sortField: string = '', _pageNumber: number = 0, _pageSize: number = 10, _status: number = -1) {
        this.searchtext = _searchtext;
        this.sortOrder = _sortOrder;
        this.sortField = _sortField;
        this.pageNumber = _pageNumber;
        this.pageSize = _pageSize;
        this.Status = _status;
    
     
    }
}
