// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../../core/_base/crud';
// Services and Models
import { processService, processmusteriModel } from '../../../../../../core/crm';
import { LayoutUtilsService, MessageType } from '../../../../../../core/_base/crud/utils/layout-utils.service';
import { JsonPipe } from '@angular/common';


// Actions

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-processmusteri-lastvalue-dialog',
    templateUrl: './processmusteri-lastvalue.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class processmusteriLastValueDialogComponent implements OnInit, OnDestroy {
    // Public properties
    processmusteri: processmusteriModel;
    processmusteriForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

    selectedprocessmusteriId = 0;
    processmusteriResult: processmusteriModel[] = [];
    deviceLastdatas = null;
    setScenario = -1;


    // Private properties
    private componentSubscriptions: Subscription;


    constructor(public dialogRef: MatDialogRef<processmusteriLastValueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private typesUtilsService: TypesUtilsService,
        private processmusteriService: processService,
        private layoutUtilsService: LayoutUtilsService,
        private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.processmusteri = this.data.model;
      
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    

    processmusteriChangeAction() {

        this.cdRef.detectChanges();
    }
    /**
     * Returns page title
     */
    getTitle(): string {
        return `Müşteri Adı :  '${this.processmusteri.customertitle}'    İşlem Kayıt Tarihi :  '${this.processmusteri.giriszamani}'`;
    }




    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.processmusteriForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

   


    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }
}
