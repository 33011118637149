import { Injectable } from '@angular/core';


@Injectable()
export class ayarevicarilerQueryParamsModel {

    searchtext: string;
    sortOrder: string; // asc || desc
    sortField: string;
    pageNumber: number;
    pageSize: number;
    Status: number;
    // vergitc: string;
    // customercode: string;
    // customertitle : string;


    // constructor overrides
    // tslint:disable-next-line: variable-name
    constructor(_searchtext: string = '', _sortOrder: string = 'asc', _sortField: string = '', _pageNumber: number = 0, _pageSize: number = 10, _status: number = -1//_vergitc:string,_customercode:string,_customertitle:string
    ) {
        this.searchtext = _searchtext;
        this.sortOrder = _sortOrder;
        this.sortField = _sortField;
        this.pageNumber = _pageNumber;
        this.pageSize = _pageSize;
        this.Status = _status;
        // this.vergitc = _vergitc;
        // this.customercode = _customercode;
        // this.customertitle = _customertitle;
    }
}
