// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'kt-update-status-dialog',
	templateUrl: './update-status-dialog.component.html'
})
export class UpdateStatusDialogComponent implements OnInit {
	drpStatus = new FormControl('');
	valueText1 = new FormControl('');
	valueText2 = new FormControl('');
	valueText3 = new FormControl('');
	viewLoading: boolean = false;
	loadingAfterSubmit: boolean = false;
	constructor(
		public dialogRef: MatDialogRef<UpdateStatusDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		//setTimeout(() => {
			this.viewLoading = false;
		//}, 2500);
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	

	updateStatus() {
		

		/* Server loading imitation. Remove this */
		this.viewLoading = true;
		this.loadingAfterSubmit = true;
		//setTimeout(() => {
			this.dialogRef.close({ status: this.drpStatus.value, valueText1: this.valueText1.value, valueText2: this.valueText2.value, valueText3: this.valueText3.value }); // Keep only this row
		// }, 2500);
	}
}
