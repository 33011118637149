// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  MatDialog } from '@angular/material/dialog';
// Partials for CRUD
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    AlertEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../../views/partials/content/crud';

export enum MessageType {
    Create,
    Read,
    Update,
    Delete,
    Error
}

@Injectable()
export class LayoutUtilsService {
    /**
	 * Service constructor
	 *
	 * @param snackBar: MatSnackBar
	 * @param dialog: MatDialog
	 */
    constructor(private snackBar: MatSnackBar,
        private dialog: MatDialog) { }

    /**
	 * Showing (Mat-Snackbar) Notification
	 *
	 * @param message: string
	 * @param type: MessageType
	 * @param duration: number
	 * @param showCloseButton: boolean
	 * @param showUndoButton: boolean
	 * @param undoButtonDuration: number
	 * @param verticalPosition: 'top' | 'bottom' = 'top'
	 */
    showActionNotification(
        _message: string,
        _type: MessageType = MessageType.Create,
        _duration: number = 10000,
        _showCloseButton: boolean = true,
        _showUndoButton: boolean = true,
        _undoButtonDuration: number = 3000,
        _verticalPosition: 'top' | 'bottom' = 'top'
    ) {
        const _data = {
            message: _message,
            snackBar: this.snackBar,
            showCloseButton: _showCloseButton,
            showUndoButton: _showUndoButton,
            undoButtonDuration: _undoButtonDuration,
            verticalPosition: _verticalPosition,
            type: _type,
            action: 'Undo'
        };
        return this.snackBar.openFromComponent(ActionNotificationComponent, {
            duration: _duration,
            data: _data,
            verticalPosition: _verticalPosition
        });
    }

    /**
	 * Showing Confirmation (Mat-Dialog) before Entity Removing
	 *
	 * @param title: stirng
	 * @param description: stirng
	 * @param waitDesciption: string
	 */
    deleteElement(title: string = '', description: string = '', waitDesciption: string = '', buttontext: string = '') {
        return this.dialog.open(DeleteEntityDialogComponent, {
            data: { title, description, waitDesciption,buttontext },
            width: '500px'
        });
    }

    /**
 * Showing Confirmation (Mat-Dialog) before Entity Removing
 *
 * @param title: stirng
 * @param description: stirng
 * @param waitDesciption: string
 */
    alertElement(title: string = '', description: string = '', waitDesciption: string = '', buttontext: string) {
        return this.dialog.open(AlertEntityDialogComponent, {
            data: { title, description, waitDesciption, buttontext },
            width: '500px'
        });
    }

    alertDeviceElement(title: string = '', description: string = '', waitDesciption: string = '', buttontext: string, type: number, item: any) {
        return this.dialog.open(AlertEntityDialogComponent, {
            data: { title, description, waitDesciption, buttontext, type, item },
            width: '500px'
        });
    }

    /**
	 * Showing Fetching Window(Mat-Dialog)
	 *
	 * @param _data: any
	 */
    fetchElements(_data) {
        return this.dialog.open(FetchEntityDialogComponent, {
            data: _data,
            width: '400px'
        });
    }

    /**
	 * Showing Update Status for Entites Window
	 *
	 * @param title: string
	 * @param statuses: string[]
	 * @param messages: string[]
	 */
    updateStatusForEntities(title, statuses, messages, drptitle, titleText1, titleText1Id, titleText2, titleText2Id, titleText3, titleText3Id) {
        return this.dialog.open(UpdateStatusDialogComponent, {
            data: { title, statuses, messages, drptitle, titleText1, titleText1Id, titleText2, titleText2Id, titleText3, titleText3Id },
            width: '480px'
        });
    }
}
