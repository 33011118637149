// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
// Services and Models
import { sitewidgetsService, usersActionToggleLoading, sitewidgetsModel } from '../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../core/_base/crud/utils/layout-utils.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-sitewidgets-edit-dialog',
    templateUrl: './sitewidgets-edit.dialog.component.html',
    styleUrls: ['./sitewidgets-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class sitewidgetsEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new usersActionToggleLoading({ isLoading: true });

    // Public properties
    sitewidgets: sitewidgetsModel;
    sitewidgetsForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;
    filterWidget = 'textcontent';
    filterSize = 'col-md-6 col-sm-12';

    selectedusersId = 0;
    sitewidgetsResult: sitewidgetsModel[] = [];

    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<sitewidgetsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitewidgetsService: sitewidgetsService,
        private layoutUtilsService: LayoutUtilsService,
        private cdRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.sitewidgets = this.data.users;
        if (this.sitewidgets.recordid === null || this.sitewidgets.recordid === undefined) { this.sitewidgets.recordid = 0; }
        if (this.sitewidgets.recordid == 0) this.createForm();
        else this.editForm();
        this.isEditrow = true;
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    usersChangeAction() {
        this.cdRef.detectChanges();


    }

    onFileBgImageChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.sitewidgetsForm.get('Bgimage').setValue(file);
        }
    }

    createForm() {

        this.sitewidgetsForm = this.fb.group({

            RecordId: [0, Validators.nullValidator],
            Widgetname: [this.filterWidget, Validators.nullValidator],
            Size: [this.filterSize, Validators.nullValidator],
            Widgetorder: [this.data.itemcount + 1, Validators.nullValidator],
        });
        this.cdRef.detectChanges();
    }
    editForm() {

        this.sitewidgetsForm = this.fb.group({

            RecordId: [this.sitewidgets.recordid > 0 ? this.sitewidgets.recordid : 0, Validators.nullValidator],
            Widgetname: [this.sitewidgets.widgetname, Validators.nullValidator],
            Size: [this.sitewidgets.size, Validators.nullValidator],
            Widgetorder: [this.sitewidgets.widgetorder, Validators.nullValidator],
        });
        this.filterSize = this.sitewidgets.size;
        this.filterWidget = this.sitewidgets.widgetname;
        this.cdRef.detectChanges();
    }

    getTitle(): string {
        if (this.sitewidgets.recordid > 0) {
            return `Komponent Düzenleme`;
        }

        return 'Yeni Komponent';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.sitewidgetsForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    prepareusers(): sitewidgetsModel {
        const controls = this.sitewidgetsForm.controls;
        const users = new sitewidgetsModel();
        users.recordid = 0;
        users.size = this.filterSize;
        users.widgetname = this.filterWidget;
        users.widgetorder = controls.Widgetorder.value;
        if (this.sitewidgets.recordid > 0) {
            users.recordid = this.sitewidgets.recordid;
        }
        else
            users.pagerowid = this.sitewidgets.pagerowid
        return users;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.sitewidgetsForm.controls;
        if (this.sitewidgetsForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const editsitewidgets = this.prepareusers();
        if (editsitewidgets.recordid > 0) {
            this.updatesitewidgets(editsitewidgets);
        } else {
            this.updatesitewidgets(editsitewidgets);
        }
    }

    updateusers(users: sitewidgetsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitewidgetsService.updatesitewidgets(users, Number(localStorage.getItem('UserId'))).subscribe(response => {

            if (response.status === 0) {
                this.store.dispatch(this.hideActionLoadingDistpatcher);
                this.dialogRef.close({ insertedid: response.value, status: response.status });
                // this.layoutUtilsService.alertElement('İşlem başarıyla gerçekleşti', 'İşlem başarılı!', '', 'Tamam');
            }
            else {
                this.layoutUtilsService.alertElement('İşlem gerçekleşemedi', 'İşlem gerçekşemedi!', '', 'Tamam');
            }


        });

    }

    createusers(users: sitewidgetsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitewidgetsService.createsitewidgets(users, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            let StatusMessage = '';
            if (response.errors !== null && response.errors !== undefined && response.errors.length > 0) {
                StatusMessage = response.errors[1].messageDescription;
            }
            this.dialogRef.close({ insertedid: response.value, status: response.status, statusMessage: StatusMessage });
        });
    }

    creatpagerows(users: sitewidgetsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        const formData = new FormData();
        formData.append('recordid', users.recordid.toString());
        formData.append('pagerowid', this.sitewidgets.pagerowid.toString());
        formData.append('size', users.size);
        formData.append('widgetname', users.widgetname);
        formData.append('widgetorder', users.widgetorder.toString());




    }

    updatesitewidgets(sitewidgets: sitewidgetsModel) {
        this.store.dispatch(this.showActionLoadingDistpatcher);
        this.sitewidgetsService.updatesitewidgets(sitewidgets, Number(localStorage.getItem('UserId'))).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            this.dialogRef.close({ insertedid: response.value, status: response.status });
        });
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }



}
