// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../../../../core/reducers';
// Services and Models
import { sitewidgetdetailsService, sitewidgetdetailsModel, sitepagesActionToggleLoading } from '../../../../../../../../core/crm';
import { LayoutUtilsService } from '../../../../../../../../core/_base/crud/utils/layout-utils.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-timeline-edit-dialog',
    templateUrl: './timeline-edit.dialog.component.html',
    styleUrls: ['./timeline-edit.dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line: class-name
export class timelineEditDialogComponent implements OnInit, OnDestroy {

    hideActionLoadingDistpatcher = new sitepagesActionToggleLoading({ isLoading: false });
    showActionLoadingDistpatcher = new sitepagesActionToggleLoading({ isLoading: true });

    // Public properties
    sitewidgetdetail: sitewidgetdetailsModel;
    form: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    isEditrow = true;

    selectedsitepagesId = 0;
    sitewidgetdetails: sitewidgetdetailsModel[] = [];
    ImageName = '';
    filterType = 'img';
    type: boolean = false;
    // Private properties
    private componentSubscriptions: Subscription;

    constructor(public dialogRef: MatDialogRef<timelineEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        // tslint:disable-next-line: no-shadowed-variable
        private sitewidgetdetailsService: sitewidgetdetailsService,
        private layoutUtilsService: LayoutUtilsService,
        private cdRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.sitewidgetdetail = this.data.item;
        this.filterType = this.sitewidgetdetail.text
        if (this.sitewidgetdetail.text === 'text') { this.type = true; }

        if (this.sitewidgetdetail.recordid === null || this.sitewidgetdetail.recordid === undefined) { this.sitewidgetdetail.recordid = 0; }
        this.createForm();
        this.isEditrow = true;
    }

    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    typeChange() {
        this.type = this.filterType === 'img' ? false : true;
        this.cdRef.detectChanges();
    }

    sitepagesChangeAction() {
        this.cdRef.detectChanges();
    }

    createForm() {

        this.form = this.fb.group({
            img: [this.sitewidgetdetail.text1, Validators.nullValidator],
            text: [this.sitewidgetdetail.text, Validators.nullValidator],
            text1: [this.sitewidgetdetail.text1, Validators.nullValidator],
            text2: [this.sitewidgetdetail.text2, Validators.nullValidator],

        });

        this.cdRef.detectChanges();
    }

    getTitle(): string {
        if (this.data.item.recordid > 0) {
            return `Düzenlenen zaman çizelgesi :   ${this.data.item.text === 'img' ? 'Resim' : this.data.item.text1}`;
        }

        return 'Yeni zaman çizelgesi';
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.form.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }


    preparesitewidgetdetailsModel(): FormData {
        const controls = this.form.controls;
        const formData = new FormData();
        formData.append('recordid', this.data.item.recordid.toString());
        formData.append('domain', this.data.settings.domain.toString());
        formData.append('img', controls.img.value);
        formData.append('text', controls.text.value);
        formData.append('text1', controls.text1.value);
        formData.append('text2', controls.text2.value);
        return formData;
    }

    onSubmit() {
        this.hasFormErrors = false;
        const controls = this.form.controls;
        if (this.form.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }
        const data = this.preparesitewidgetdetailsModel();

        this.sendDataWidgetDetails(data);
    }

    sendDataWidgetDetails(WidgetDetails: FormData) {

        this.sitewidgetdetailsService.insertcomponentFormDataTimeline(WidgetDetails).subscribe(response => {
            this.store.dispatch(this.hideActionLoadingDistpatcher);
            this.dialogRef.close({ insertedid: response.value, status: response.status });
        });
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('img').setValue(file);
        }
    }


    onAlertClose($event) {
        this.hasFormErrors = false;
    }
}
